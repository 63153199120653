import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import Popup from 'reactjs-popup';
import { Redirect, Routes, Route, useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import ReactNotifications, { store } from 'react-notifications-component';

import actions from '../../redux/actions';
import common from '../../common';
import ContentFilter from '../../components/study-material/ContentFilter';
import PdfPlayer2 from '../../components/players/pdf/pdfPlayer2';
import VideoPlayer2 from '../../components/players/video/VideoPlayer2';
import { AssessmentPreview } from '../../components/study-material/CommonComponents';
import SolvedExample from '../../components/study-material/SolvedExample';
import Qna from '../../public/icons/qna.svg';
import Back from '../../public/icons/back.svg';
import Left from '../../public/icons/left.svg';
import Right from '../../public/icons/right.svg';
import ImgHolder from '../../public/icons/doubt-image.svg';
import AssignmentPreview from '../../components/study-material/commonAssignment';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import config from '../../config.js';
let Savetime = '';


class ContentView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      is_faculty: localStorage.getItem('is_faculty') === 'true' ? true : false,
      gmail_source: localStorage.getItem('gmail_source'),
      subject_id: this.props.match.params.id,
      c_id: this.props.match.params.content_id,
      t_id: this.props.match.params.table_id,
      c_type: this.props.match.params.content_type,
      table_id: `${this.props.location.state?.table_id}`,
      units: [],
      toc_ids: [],
      toc_id_list: [],
      toc_table: [],
      posted: false,
      question: null,
      subjects: [],
      no_data: false,
      subject: null,
      description: '',
      image: null,
      imageURL: null,
      wChange: false,
      batch_id: 0,
      id: 0,
      contentDetails: [],
      video_played_time: 0,
      class_id: 0,
      is_archive: '',
      flag: true,
      checkExternalFile: localStorage.getItem("checkExternalFile"),
      notesFileExt: localStorage.getItem("notesFileExt"),
    }
  }

  componentDidMount() {




    setTimeout(() => {
      let checkExternalFile = localStorage.getItem("checkExternalFile");
      this.setState({ checkExternalFile: localStorage.getItem("checkExternalFile") })
    }, 1000);

    const { subject_id, c_id, t_id, c_type } = this.state

    console.log("checkExternalFile", this.state.checkExternalFile)
    console.log("fromStudyMaterial")
    console.log(this.props?.location?.state?.fromStudyMaterial)
    console.log(this.props?.location?.state?.assignment_id)
    console.log(this.props?.location?.state?.batch_id);
    if (this.props?.location?.state?.batch_id) {
      this.setState({ batch_id: this.props?.location?.state?.batch_id })
    }

    console.log("this.props.location?.state.is_archive");
    console.log(this.props?.location?.state?.is_archive);
    let getis_archive = this.props.location?.state?.is_archive;
    this.setState({ is_archive: getis_archive });

    //  if(this.props?.location?.state?.fromStudyMaterial){
    //   setTimeout(() => {
    //     console.log("fromStudyMaterial getIDs")
    //     const getIDs =  document.getElementById('content-selec');
    //     getIDs.click();
    //   }, 3000);
    //  }


    if (this.props?.location?.state?.fromStudyMaterial && this.props?.location?.state?.assignment_id) {
      localStorage.setItem('preview_content_id', this.props?.location?.state?.assignment_id);
      setTimeout(() => {
        const getIDs = document.getElementById('content-selec');
        getIDs.click();
      }, 3000);

    }

    // if (this.props?.location?.state?.fromStudyMaterial && this.props?.location?.state?.content_subtype_name && this.props?.location?.state?.content_id) {
    //   localStorage.setItem('content_subtype_name', this.props?.location?.state?.content_subtype_name);
    //   localStorage.setItem('preview_content_id', this.props?.location?.state?.content_id);
    //   setTimeout(() => {
    //     const GETID = document.getElementById('content-selec');
    //     GETID.click()
    //   }, 3000);
    // }
    if (this.props?.location?.state?.fromStudyMaterial && this.props?.location?.state?.content_subtype_name && this.props?.location?.state?.content_id) {
      localStorage.setItem('content_subtype_name', this.props?.location?.state?.content_subtype_name);
      localStorage.setItem('preview_content_id', this.props?.location?.state?.content_id ?? 0);
      
      setTimeout(() => {
          try {
              const GETID = document.getElementById('content-selec');
              if (GETID) {
                  GETID.click();
              } else {
                  console.error("Element with ID 'content-selec' not found");
              }
          } catch (error) {
              console.error("Error clicking on element:", error);
          }
      }, 3000);
  }
  



    if (this.props.location?.state?.fromDashboard && this.props.location?.state?.content_subtype_name && this.props?.location?.state?.content_id) {
      localStorage.setItem('content_subtype_name', this.props.location?.state?.content_subtype_name);
      localStorage.setItem('preview_content_id', this.props.location?.state?.content_id);
      setTimeout(() => {
        const GETID = document.getElementById('content-selec');
        GETID.click()
      }, 3000);
    }

    if (localStorage.getItem('is_faculty') === 'false') {
      this.getLearnerProfile();
    }
    if (localStorage.getItem('is_faculty') === 'true') {
      this.getFacultyProfile();
    }
    if (this.state.table_id != "undefined") {
      console.log("account ddddd" + this.state.table_id);
      this.changeTocTable(parseInt(this.state.table_id));
    }

    if (parseInt(localStorage.getItem('preview_content_id')))
      this.props.getContentPreview(parseInt(localStorage.getItem('preview_content_id')), parseInt(localStorage.getItem('content_subtype_name')));
    if (this.props?.location?.state?.fromStudyMaterial) {
      setTimeout(() => {
        const getIDs = document.getElementById('content-selec');
        if (getIDs) {
          getIDs.click();
        }

      }, 3000);
    }

    /*if(this.props.location.pathname.split('/')[3] == 436){
      localStorage.setItem('preview_content_id','1060')
      localStorage.setItem('content_table_id',601)
      localStorage.setItem('content_subtype_name',1)
      //setTimeout(() => window.location.reload(), 1000);
      setTimeout(function () {
          if(window.location.hash != '#r') {
              window.location.hash = 'r';
              window.location.reload(1);
          }
      }, 100); 
    }
    if(this.props.location.pathname.split('/')[3] == 485){
      localStorage.setItem('preview_content_id','1237')
      localStorage.setItem('content_table_id',691)
      localStorage.setItem('content_subtype_name',1)
      //setTimeout(() => window.location.reload(), 1000);
      setTimeout(function () {
          if(window.location.hash != '#r') {
              window.location.hash = 'r';
              window.location.reload(1);
          }
      }, 100); 
    }*/

    if (subject_id && c_id && t_id && c_type) {
      localStorage.setItem('preview_content_id', c_id)
      localStorage.setItem('content_table_id', t_id)
      localStorage.setItem('content_subtype_name', 4)
      localStorage.setItem('content_subtype_name', c_type)
      //setTimeout(() => window.location.reload(), 1000);
      setTimeout(function () {
        if (window.location.hash != '#r') {
          window.location.hash = 'r';
          window.location.reload(1);
        }
      }, 100);
    }

    this.getStudyMaterial();

    if (localStorage.getItem('lang')) {
      let language = localStorage.getItem('lang');
      console.log("language", language);
      this.fetchLanguageWiseData(language);
    }
  }

  fetchLanguageWiseData = async (language) => {
    const response = await this.props.getLanguageWiseData(language, localStorage.getItem("is_faculty") ? "faculty" : "learner");
    if (response !== undefined && response.status == 1) {
      console.log("getLanguageWiseData");
      console.log(response?.data);
      localStorage.setItem('languageData', JSON.stringify(response?.data))


    }
  }

  getLearnerProfile = async () => {
    //if(this.state.table_id && this.state.table_id !=undefined) localStorage.setItem("content_table_id",this.state.table_id)
    const response = await this.props.getLearnerProfile();
    this.setState({
      subjects: response.data.subjects, loading: false,
      no_data: response.data.message == "You are not assigned to any class and section, Please contact Admin for assigning into class"
        ? true : false
    })
  }

  getFacultyProfile = async () => {
    //if(this.state.table_id && this.state.table_id !=undefined) localStorage.setItem("content_table_id",this.state.table_id)
    const response = await this.props.getFacultyProfile();
    this.getSubject(response.data);
    this.getBatchName(response.data.batches);
  }

  getSubject = (val) => {
    const subjectId = this.props.match.params;
    console.log("Subject id");
    console.log(this.props.match.params);
    if (val.subjects && val.subjects.length) {
      for (let i = 0; i < val.subjects.length; i++) {
        console.log(val.subjects[i].id)
        if (parseInt(subjectId.id) === parseInt(val.subjects[i].id)) {

          this.setState({ class_id: val.subjects[i].class_id });
        }
      }
    }
  }

  getBatchName = (val) => {
    if (val && val.length) {
      for (let i = 0; i < val.length; i++) {
        if (parseInt(val[i].class_id) === parseInt(this.state.class_id))
          this.setState({ batch_id: val[i].id });

        console.log("batch_id");
        console.log(val[i].id);
      }
    }
  }


  show = () => this.setState({
    open: true, description: '', image: null, imageURL: null,
    subject: null
  });

  close = () => this.setState({ open: false });

  onWChange = () => {
    this.setState({ wChange: !this.state.wChange });
  }

  handleChange = (event) => {
    if (event.target.files[0].type.split('/')[0] === 'image') {
      this.setState({
        image: event.target.files[0],
        imageURL: URL.createObjectURL(event.target.files[0])
      })
    }
    else {
      this.setState({ image: null, imageURL: null });
      document.getElementById('doubt_img').value = "";
      store.addNotification({
        title: 'Error',
        message: `${this.props.saveLanguageWiseData.images_uploaded}`,
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  }

  removeImage = () => {
    this.setState({ image: null, imageURL: null });
    document.getElementById('doubt_img').value = "";
  }

  onSubjectChange = (val) => {
    this.setState({ subject: val });
  }

  onDescriptionChange = (e) => {
    this.setState({ description: e.target.value });
  }

  postDoubt = async () => {
    const { subject, description, image } = this.state;
    const response = await this.props.postDoubt(subject, description, image, null);
    if (response !== undefined) {
      this.setState({ posted: true, question: response.data, open: false });
    }
  }

  getStudyMaterial = async () => {


    const response = await this.props.getTocList(this.state.subject_id);
    if (!isEmpty(this.state.table_id)) {
      this.setState({ toc_table: common.getContentIds(response.toc_data.units)[this.state.table_id] });
    }
    else {
      this.setState({
        table_id: `${Object.keys(common.getContentIds(response.toc_data.units))[0]}`,
        toc_table: common.getContentIds(response.toc_data.units)[parseInt(Object.keys(common.getContentIds(response.toc_data.units))[0])]
      })
    }
    this.setState({ toc_ids: common.getContentIds(response.toc_data.units), loading: false });
    this.setState({ toc_id_list: common.getContentIdList(response.toc_data.units), loading: false });

    if (parseInt(localStorage.getItem("content_table_id"))) {

      this.changeTocTable(parseInt(localStorage.getItem("content_table_id")));

      let tocDetails = common.getContentIds(response.toc_data.units)[parseInt(localStorage.getItem("content_table_id"))];

      this.setState({ contentDetails: tocDetails?.filter(p => parseInt(p.content_id) === parseInt(this.props.selectedContentId)) })
    }

    try {
      const ele = document.getElementsByClassName(`has-descrip_${this.props?.location?.state?.content_id}`)[0]
      ele.click()
    }
    catch (e) {
      console.log("auto click", e)
    }

  }

  /*****

  content Table and content Id mapping

  ******/
  getContentTableId = (tocdata) => {
    var result = {};
    var content = {};
    var keyList = [];
    for (let i = 0; i < tocdata.length; i++) {
      var chapters = tocdata[i].chapters;
      result[tocdata[i].id] = tocdata[i].content;

      for (let c = 0; c < tocdata[i].content.length; c++) {
        content[tocdata[i].id + '-' + tocdata[i].content[c].content_id] = tocdata[i].content[c].content_id;
      }

      for (let j = 0; j < chapters.length; j++) {
        var topics = chapters[j].topics;
        result[chapters[j].id] = chapters[j].content;
        for (let c1 = 0; c1 < chapters[j].content.length; c1++) {
          content[chapters[j].id + '-' + chapters[j].content[c1].content_id] = chapters[j].content[c1].content_id;
        }

        for (let k = 0; k < topics.length; k++) {
          var subtopics = topics[k].subtopics;
          result[topics[k].id] = topics[k].content;
          for (let c2 = 0; c2 < topics[k].content.length; c2++) {
            content[topics[k].id + '-' + topics[k].content[c2].content_id] = topics[k].content[c2].content_id;
          }

          for (let l = 0; l < subtopics.length; l++) {
            result[subtopics[l].id] = subtopics[l].content;

            for (let c3 = 0; c3 < subtopics[l].content.length; c3++) {
              content[subtopics[l].id + '-' + subtopics[l].content[c3].content_id] = subtopics[l].content[c3].content_id;
            }
          }
        }
      }
    }



    let contentList = Object.entries(content).reduce((ret, entry) => {
      const [key, value] = entry;

      let preview_content_id = parseInt(localStorage.getItem('preview_content_id'));

      if (preview_content_id == value) {
        localStorage.setItem("content_table_id", key.split("-")[0])
      }
    }, {});

    //console.log(contentList) 
  }



  /******************************************/

  changeTocTable = (val) => {
    if (val != undefined) {
      this.setState({ table_id: val, toc_table: this.state.toc_ids[val] });
      localStorage.setItem('content_table_id', val);
    }
  }

  navigateContent = (i) => {

    var length = this.state.toc_table.length;
    var arr1 = [];

    var arr = this.state.toc_table.map(content => content.assignment_id ? content.assignment_id : content.content_id);
    var index = arr.indexOf(this.props.selectedContentId);
    console.log("index" + index)
    //var keys = Object.keys(this.state.toc_ids);
    //var keys  = this.state.toc_id_list.keys(); 
    //console.log("keys"+keys)
    //var arr1 = keys.map(content => parseInt(content));
    var keys = this.state.toc_id_list;

    for (let val in this.state.toc_id_list) {
      arr1.push(parseInt(this.state.toc_id_list[val]))
    }

    console.log(this.state.toc_id_list)
    console.log(arr1)
    var index2 = this.state.toc_id_list.indexOf(this.state.table_id);

    if (index2 == -1) index2 = arr1.indexOf(parseInt(this.state.table_id));


    console.log("index2" + index2)

    if (i === 1 && index + 1 < length) {

      this.props.getContentPreview(this.state.toc_table[index + 1].assignment_id ? this.state.toc_table[index + 1].assignment_id : this.state.toc_table[index + 1].content_id, this.state.toc_table[index + 1].content_subtype);
    }
    else if (i === 1 && index + 1 >= length && index2 < keys.length - 1) {
      index2 = index2 + 1;
      localStorage.setItem('content_table_id', keys[index2]);
      this.setState({ toc_table: this.state.toc_ids[keys[index2]], table_id: keys[index2] });
      this.props.getContentPreview(this.state.toc_ids[keys[index2]][0]?.assignment_id ? this.state.toc_ids[keys[index2]][0]?.assignment_id : this.state.toc_ids[keys[index2]][0]?.content_id,
        this.state.toc_ids[keys[index2]][0]?.content_subtype);
    } else if (index == -1 && i === 1) {

      localStorage.setItem('content_table_id', keys[0]);
      var xlen = this.state.toc_ids[keys[0]].length;
      this.setState({ toc_table: this.state.toc_ids[keys[0]], table_id: keys[0] });
      this.props.getContentPreview(this.state.toc_ids[keys[0]][0]?.assignment_id ? this.state.toc_ids[keys[0]][0]?.assignment_id : this.state.toc_ids[keys[0]][0]?.content_id,
        this.state.toc_ids[keys[0]][0]?.content_subtype);
    }

    if (i === -1 && index > 0) {
      this.props.getContentPreview(this.state.toc_table[index - 1].assignment_id ? this.state.toc_table[index - 1].assignment_id : this.state.toc_table[index - 1].content_id,
        this.state.toc_table[index - 1].content_subtype);
    }
    else if (i === -1 && index === 0 && index2 > 0) {
      index2 = index2 - 1;
      localStorage.setItem('content_table_id', keys[index2]);
      var xlen = this.state.toc_ids[keys[index2]].length;
      this.setState({ toc_table: this.state.toc_ids[keys[index2]], table_id: keys[index2] });
      this.props.getContentPreview(this.state.toc_ids[keys[index2]][xlen - 1]?.assignment_id ? this.state.toc_ids[keys[index2]][xlen - 1]?.assignment_id : this.state.toc_ids[keys[index2]][xlen - 1]?.content_id,
        this.state.toc_ids[keys[index2]][xlen - 1]?.content_subtype);
    } else if (index == -1 && i === -1) {
      localStorage.setItem('content_table_id', keys[index2 - 1]);
      var xlen = this.state.toc_ids[keys[index2 - 1]].length;
      this.setState({ toc_table: this.state.toc_ids[keys[index2 - 1]], table_id: keys[index2 - 1] });

      this.props.getContentPreview(this.state.toc_ids[keys[index2]][xlen - 1].assignment_id ? this.state.toc_ids[keys[index2]][xlen - 1].assignment_id : this.state.toc_ids[keys[index2]][xlen - 1].content_id,
        this.state.toc_ids[keys[index2]][xlen - 1].content_subtype);
    }
  }

  directContentPreview = async (assignment_id, content_id, content_subtype) => {

    if (assignment_id) {
      localStorage.setItem('preview_content_id', assignment_id);
      localStorage.setItem('content_subtype_name', content_subtype);
      await this.props.getContentPreview(assignment_id, content_subtype);
    }
    else {
      localStorage.setItem('preview_content_id', content_id);
      localStorage.setItem('content_subtype_name', content_subtype);
      await this.props.getContentPreview(content_id, content_subtype);

      const response = await this.props.getTocList(this.state.subject_id);

      if (parseInt(localStorage.getItem("content_table_id"))) {
        let tocDetails = common.getContentIds(response.toc_data.units)[parseInt(localStorage.getItem("content_table_id"))];

        console.log(tocDetails.filter(p => parseInt(p.content_id) === parseInt(this.props.selectedContentId)))
        this.setState({ contentDetails: tocDetails.filter(p => parseInt(p.content_id) === parseInt(this.props.selectedContentId)) })
        //this.changeTocTable(parseInt(localStorage.getItem("content_table_id")));
      }
    }
    this.getTableId();
    // if(this.state.flag){
    //   setTimeout(() => {
    //     const getIDs =  document.getElementById('content-selec');
    //     getIDs.click();
    //   }, 3000);
    //   this.setState({flag:false})
    // }
  }

  getTableId = () => {
    var change = 0;
    for (var key in this.state.toc_ids) {
      if (change === 1) {
        break;
      }
      if (this.state.toc_ids.hasOwnProperty(key) && change === 0) {
        var arr = this.state.toc_ids[key];
        for (let i = 0; i < arr.length; i++) {
          if (this.props.selectedContentId == arr[i].content_id) {
            this.setState({ toc_table: arr, table_id: key });
            change = 1;
            break;
          }
        }
      }
    }
  }

  attemptPaper = (label) => {
    const contentPreview = this.props.contentPreview;
    if (label.toLowerCase() === 'result') {
      if (localStorage.getItem('is_faculty') === 'false') {
        this.props.history.push({
          pathname: `/learner/assessment-review/${contentPreview.content_id}`,
        });
      }
    }


    if (label.toLowerCase() === 'review') {
      if (localStorage.getItem('is_faculty') === 'false') {
        this.props.history.push({
          pathname: `/learner/assessment-review/${contentPreview.content_id}`,
          state: { modal: true }
        });
      }
      else if (localStorage.getItem('is_faculty') === 'true') {
        this.props.history.push({
          pathname: `/faculty/assessment-review/${contentPreview.content_id}`,
          state: { batch: contentPreview.batch_id ? contentPreview.batch_id : this.state.batch_id, modal: true, is_subject: 1, class_id: this.state.class_id }
        });
      }
    }
    else {
      if (localStorage.getItem('is_faculty') === 'false' && label.toLowerCase() != 'result') {
        this.props.history.push(`/learner/attempt/${contentPreview.content_id}`);
      }
      else if (localStorage.getItem('is_faculty') === 'true') {
        this.props.history.push(`/faculty/attempt/${contentPreview.content_id}`);
      }
    }
  }


  historyGoBack = (e) => {

  }


  pdfPageChange = async (e) => {
    let content_id = parseInt(localStorage.getItem("SaveContentID"));
    let ref_id = parseInt(localStorage.getItem("SaveContentRefID"));
    let level = parseInt(localStorage.getItem("SaveContentLevel"));
    let content_type = parseInt(localStorage.getItem("SaveContentType"));
    let content_subtype = parseInt(localStorage.getItem("SaveContentSubType"));
    let currentPage = parseInt(localStorage.getItem("SaveCurrentPage"));
    let prevPage = parseInt(localStorage.getItem("SavePrevPage"));
    let totalPage = parseInt(localStorage.getItem("SaveTotalPage"));
    let Savetime = parseInt(localStorage.getItem("Savetime"));
    let subject = parseInt(localStorage.getItem("SaveContentSubject"));
    if (!this.state.is_faculty && !this.state.is_admin) {
      const response = await this.props.userContentCompletion(content_id, ref_id, level, content_type, content_subtype, currentPage, parseInt(totalPage), Savetime, prevPage, parseInt(subject));


    }
  }

  fileDownloadOnLink = async (url, filename) => {
    console.log("url", url)
    console.log("filename", filename.substring(filename.lastIndexOf('/') + 1))
    const data = await fetch(url)
    const blob = await data.blob()
    const objectUrl = URL.createObjectURL(blob)

    const link = document.createElement('a')

    link.setAttribute('href', objectUrl)
    link.setAttribute('download', filename.substring(filename.lastIndexOf('/') + 1))
    link.style.display = 'none'

    document.body.appendChild(link)

    link.click()

    document.body.removeChild(link)


  }
  render() {
    const { contentVideoDetail, contentNotesDetail } = this.props;
    const { posted, question, contentDetails, gmail_source } = this.state;
    const isEnabled = this.state.subject !== null && this.state.description.length > 0;
    if (this.state.loading) {
      return (
        <div className="assessment-div">
          <div className="admin-div-loader">
            <div className="admin-loader"></div>
          </div>
        </div>
      )
    }
    return (
      <div className={this.state.notesFileExt === 'pdf' ? "content-body-container pdffileonly" : 'content-body-container'}>
        <Helmet>
          <title>{`${this.props.saveLanguageWiseData.classroom} - ${this.props.saveLanguageWiseData.learner}`}</title>
        </Helmet>
        <div className="content-body">
          {posted && question !== null && <Redirect
            to={{
              pathname: `/learner/doubt-responses/${question.id}`,
              state: { question: question }
            }} />}
          {!this.state.is_faculty && <Popup open={this.state.open} closeOnDocumentClick
            onClose={this.close}
            className="popup-doubt">
            <ReactNotifications />
            <div className="popup-ask-doubt">
              <div className="popup-header">
                {this.props.saveLanguageWiseData.ask_your_doubt}
              </div>
              <span className="close" onClick={this.close}>&#x2715;</span>
              <div className="ask-doubt-content">
                <div className="popup-doubt-subjects">
                  {this.state.subjects.map(subject =>
                    <div key={subject.id} onClick={() => this.onSubjectChange(subject.id)}
                      id={this.state.subject === subject.id ? 'selected-dsubject' : ''}>
                      {subject.label}
                    </div>
                  )}
                </div>
                <div className="doubt-content-div">
                  <input style={{ display: 'none' }} accept="image/*" type="file"
                    onChange={this.handleChange} ref={fileInput => this.fileInput = fileInput}
                    id="doubt_img" />
                  <div className="image-upload designChanges">
                    <span>{this.props.saveLanguageWiseData.add_an_image}</span>
                    {this.state.imageURL === null
                      ? <img src={ImgHolder} alt="img" onClick={() => this.fileInput.click()} />
                      : <div className="post-doubt-img"
                        style={{ backgroundImage: "url(" + this.state.imageURL + ")" }}>
                        <span className="close" onClick={this.removeImage}>&#x2715;</span>
                      </div>}
                  </div>
                  <textarea placeholder="Write your question..."
                    onChange={this.onDescriptionChange} />
                </div>
              </div>
              <div className="ask-doubt-action">
                <button disabled={!isEnabled} onClick={this.postDoubt}>
                  {this.props.saveLanguageWiseData.post_doubt}
                </button>
              </div>
            </div>
          </Popup>}
          <div className="content-toc">
            <div className="content-header" style={this.state.wChange ? { width: '100%' } : {}}>
              <div className="heading">



                {gmail_source && gmail_source != "null" && !isEmpty(contentNotesDetail) ? <div id="trace-back" onClick={() => this.props.history.push('/learner')}>
                  <img src={Back} alt="back" />
                </div> : <>{gmail_source && gmail_source != "null" && !isEmpty(contentVideoDetail) ? <div id="trace-back" onClick={() => this.props.history.push('/learner')}>
                  <img src={Back} alt="back" />
                </div> : <div id="trace-back" onClick={() => this.props.history.goBack()}>
                  <img src={Back} alt="back main" />
                </div>}</>
                }


              </div>
              <div className="content-tray">
                {!isEmpty(this.state.toc_table) && this.state.toc_table.map(content =>
                  <div className="content_dash" key={content.content_id}
                    style={{ width: `calc(${100 / this.state.toc_table.length}% - 15px)` }}
                    id={content.assignment_id ? content.assignment_id : content.content_id === this.props.selectedContentId ? "content_dashed" : ""}
                    onClick={() => this.directContentPreview(content.assignment_id, content.content_id, content.content_subtype)}>
                  </div>
                )}
              </div>
              <div className="heading">
              </div>
            </div>
            {this.props.loading ? <div className="content-section-loader" >
              <div className="admin-loader"></div>
            </div> : <>
              {!isEmpty(contentNotesDetail)
                ? <div className="content-title correctionClass justify">{contentNotesDetail.title} {contentNotesDetail?.content?.downloadable && <div className="fileDownloadble" Style={localStorage.getItem("checkExternalFile") == 'false' ? '' : 'display:none'}><a onClick={() => this.fileDownloadOnLink(common.addFileUrl(contentNotesDetail?.content?.file_details[0]?.file_url), contentNotesDetail?.content?.file_details[0]?.file_url)}><i className='fa fa-download'></i>Download Notes</a></div>}</div>
                : !isEmpty(contentVideoDetail)
                  ? <div className="content-title correctionClass justify">{contentVideoDetail.title} {contentVideoDetail?.content?.downloadable && <div className="fileDownloadble" Style={localStorage.getItem("checkExternalFile") == 'false' ? '' : 'display:none'}><a onClick={() => this.fileDownloadOnLink(common.addFileUrl(contentVideoDetail?.content?.file_details[0]?.file_url), contentVideoDetail?.content?.file_details[0]?.file_url)}><i className='fa fa-download'></i>Download Video</a></div>}</div>
                  : null}

              {/* {JSON.stringify(contentNotesDetail?.content?.file_details[0]?.file_url)} */}

              <div className={this.state.wChange && !isEmpty(contentNotesDetail) ? "content-section-fullView content-section" : "content-section"}
                style={this.state.wChange ? !isEmpty(contentNotesDetail)
                  ? { width: '100%', height: `calc(100% - 115px)`, bottom: '0px' } : { width: '100%' } : {}}>
                {!isEmpty(contentNotesDetail) && !isEmpty(contentDetails) ?
                  <PdfPlayer2 url={common.addFileUrl(contentNotesDetail?.content?.file_details[0]?.file_url)} content_id={this.props.selectedContentId} subject_id={this.state.subject_id} ref_id={contentDetails[0]?.ref_id} content_subtype={contentDetails[0]?.content_subtype} content_type={contentDetails[0]?.content_type} level={contentDetails[0]?.level} current_pointer={contentDetails[0]?.current_pointer} />
                  : <>
                    {!isEmpty(contentVideoDetail) && !isEmpty(contentDetails) ?
                      <div className="video-container-bws">
                        {/* {contentVideoDetail.description === null 
                          || contentVideoDetail.description === '' ? <div></div>
                          : <div className="content-description">
                            {contentVideoDetail.description}
                          </div>} */}
                        <VideoPlayer2 wKey={contentVideoDetail?.title} wChange={this.state.wChange} onWChange={this.onWChange}
                          url={common.addFileUrl(contentVideoDetail?.content?.file_details[0]?.file_url)} content_id={this.props.selectedContentId} subject_id={this.state.subject_id} ref_id={contentDetails[0]?.ref_id} content_subtype={contentDetails[0]?.content_subtype} content_type={contentDetails[0]?.content_type} level={contentDetails[0]?.level} current_pointer_video={contentDetails[0]?.current_pointer} />
                      </div> : <>
                        {!isEmpty(this.props.contentSolvedExample) ? <SolvedExample />
                          : <>
                            {!isEmpty(this.props.contentPreview) && this.props.contentPreview.assessment_id ? <AssessmentPreview
                              contentPreview={this.props.contentPreview}
                              attempt={this.attemptPaper}
                              wChange={this.state.wChange}
                              showAttemptButton={true} is_archive={this.state.is_archive ? true : false} /> : <>
                              {!isEmpty(this.props.contentPreview) && this.props.contentPreview.uid ? <AssignmentPreview
                                contentPreview={this.props.contentPreview} assignment_id={localStorage.getItem("preview_content_id")} subject_label={this.props.location?.state?.subject_label} />
                                : <div className="select-content">
                                  <span>{this.props.saveLanguageWiseData.select_any_content_from_the_list}</span>
                                </div>
                              }
                            </>}
                          </>}
                      </>
                    }
                  </>
                }
              </div>
            </>}
            <ContentFilter content_id={this.props?.location?.state?.content_id} subjectId={this.state.subjectId} changeTocTable={this.changeTocTable}
              directContentPreview={this.directContentPreview} wChange={this.state.wChange}
              onWChange={this.onWChange} />
          </div>
          <div className="content-controller">
            <div className="content-navs">
              <button onClick={() => this.navigateContent(-1)}>
                <img src={Left} alt="prev" />
              </button>
              <button onClick={() => this.navigateContent(1)}>
                <img src={Right} alt="next" />
              </button>
            </div>
            {!this.state.is_faculty && <div title="Ask Doubt" onClick={this.show}>
              <img src={Qna} alt="qna" />
            </div>}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    contentNotesDetail: state.classroomReducer.contentNotesDetail,
    contentPreview: state.classroomReducer.contentPreview,
    contentVideoDetail: state.classroomReducer.contentVideoDetail,
    contentSolvedExample: state.classroomReducer.contentSolvedExample,
    selectedContentId: state.classroomReducer.selectedContentId,
    saveLanguageWiseData: state.commonReducer.languageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getTocList, getContentPreview, getLearnerProfile, postDoubt, getFacultyProfile, getLanguageWiseData, userContentCompletion } = actions;
  return {
    getLearnerProfile: () => dispatch(getLearnerProfile()),
    getTocList: (subjectId) => dispatch(getTocList(subjectId)),
    getContentPreview: (contentId, content_subtype) => dispatch(getContentPreview(contentId, content_subtype)),
    postDoubt: (subject_id, description, media, doubt_forum_id) => dispatch(postDoubt(subject_id, description, media, doubt_forum_id)),
    getFacultyProfile: () => dispatch(getFacultyProfile()),
    getLanguageWiseData: (language, page) => dispatch(getLanguageWiseData(language, page)),
    userContentCompletion: (content, ref_id, level, content_type, content_subtype, page_number, max_page_no, duration_taken, previous_page_number, subject) => dispatch(userContentCompletion(content, ref_id, level, content_type, content_subtype, page_number, max_page_no, duration_taken, previous_page_number, subject)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentView);