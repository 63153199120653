import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import actions from '../../redux/actions';
import Dashboard from '../../public/icons/dashboard.svg';
import Practice from '../../public/icons/practice.svg';
import Tests from '../../public/icons/tests.svg';
import Calendar from '../../public/icons/calendar-icon.svg';
import Sessions from '../../public/icons/sessions.svg';
import Doubts from '../../public/icons/doubts.svg';
import Logo from '../../public/icons/favicon.svg';
import LogoSchoolOnWeb from '../../public/icons/schoolsonweb-logo-only.png';
import common from '../../common';
// import Settings from '../../public/icons/settings.svg';
import Random from '../../public/icons/random.svg';
import './styles.css';


import xpSidebarMyCoachIcon from '../../public/icons/xpsidebar-mycoach-icon.png';
import xpSidebarChallengesIcon from '../../public/icons/xpsidebar-challenges-icon.png';
import xpSidebarEventsIcon from '../../public/icons/xpsidebar-events-icon.png';
import xpSidebarLiveProjectsIcon from '../../public/icons/xpsidebar-liveprojects-icon.png';
import xpSidebarIdeasSpaceIcon from '../../public/icons/xpsidebar-ideasspace-icon.png';
import xpSidebarStationaryShopIcon from '../../public/icons/xpsidebar-stationaryshop-icon.png';
import xpSidebarMyPercentageIcon from '../../public/icons/xpsidebar-mypercentage-icon.png';
import xpSidebarTVIcon from '../../public/icons/xpsidebar-tv-icon.png';
import xpSidebarQueryIcon from '../../public/icons/xpsidebar-query-icon.png';
import xpSidebarAcademyicon from '../../public/icons/mycoach-academy-icon.png';



import ForgotImage from '../../public/icons/Group2701.svg';
import See from '../../public/icons/see.svg';
import Hide from '../../public/icons/hide.svg';
import config from '../../config.js';
import Assignment from '../../public/icons/assignment.svg';
import MyCourse from '../../public/icons/subtopic.svg';
import Library from '../../public/icons/books.png';
import Webinar from '../../public/icons/nav-webinaricon.png';
import Workshop from '../../public/icons/nav-eventsicon.png';
import IdeaSpace from '../../public/icons/nav-ideaspaceicon.png';
import Queries from '../../public/icons/nav-queryicon.png';
import vguLogo from '../../public/images/vgu-logo1.jpg';
class SidebarLearner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: {},
      no_data: false,
      image: '',
      center_id: '',
      token:null,
      open1: false,
      hide:true,
      hide1:true,
      hide2:true,
      userid:"",
      uname:"",
      username:"",
      email:"",
      mobile:"",
      profile_picture:"",
      redirectURL:"",
      course_id:null,
      mobilenavigation:false,
      open5:false,
      getCenterId : ''
    }
  }

  componentDidMount() {
    let centerid = localStorage.getItem("center_id");
    console.log("getcenterid",centerid);
    this.setState({getCenterId:centerid})
    this.redirectURL();
    setTimeout(() => this.props.setCurrentPage(window.location.pathname), 0)

    console.log(window.location.pathname)

    if(window.location.pathname === '/mycoach' || window.location.pathname === '/mycoach/challenges' || window.location.pathname === '/mycoach/liveprojects' || window.location.pathname === '/learner/doubts'){
      
      var element = document.getElementById("bodyParam");
      element.classList.add('mycoachpageadded');
    }
  }

  close6 = () => this.setState({ open5: false });

  redirectURL = () => {
    this.setState({redirectURL:`${config.homePage}`});
  }

  getProfile = async (center) => {
    if(localStorage.getItem('token') && center !== undefined && center !== '') {
      const response = await this.props.getProfile(center);
      if(response !== undefined) {
        this.setState({ image: response.data.logo });
      }
    }
  }

  changePassword = async (username,password,new_password) => {
    const response = await this.props.changePassword(username,password,new_password,localStorage.getItem('lang'));
    if(response !=undefined){
      this.setState({open1:false});
      this.props.userLogin(username,new_password,0);
    }
  }

  handleChangePassword = (e) => {
    this.setState({ password: e.target.value })
  }

  handleChangeNewPassword = (e) => {
    //e.target.value ===this.state.password?this.setState({confirm_password:true}):this.setState({confirm_password:false});
    this.setState({ new_password: e.target.value ,confirm_password:'',is_password_correct:0})
  }

  handleChangeConfirmPassword = (e) => {
    this.setState({confirm_password:e.target.value});

    e.target.value===this.state.new_password?this.setState({is_password_correct:1}):this.setState({is_password_correct:0});
  }

  hideChange = () => this.setState({ hide: !this.state.hide })

  hideChange1 = () => this.setState({ hide1: !this.state.hide1 })

  hideChange2 = () => this.setState({ hide2: !this.state.hide2 })
  

  changeTab = (e) => {
    setTimeout(() => this.props.setCurrentPage(window.location.pathname), 0);
    var element = document.getElementById("bodyParam");
    element.classList.remove('mycoachpageadded');
    localStorage.removeItem("ideaspaceid");
    localStorage.removeItem("current_page");
    localStorage.removeItem("doubtid");

    this.setState({ mobilenavigation: false });
    
  }

  show3 = () => this.setState({ open1: true });
  close = () => this.setState({ open1: false });

  menuToggleActions(){
    const currentState = this.state.mobilenavigation;
    this.setState({ mobilenavigation: !currentState });
  }

  menuToggleClose(){
    this.setState({ mobilenavigation: false });
  }

  goToAssessment = () =>{
    window.open("/learner/classroom/436","_self")
  }

  mobileCloseclick(){
      var element = document.getElementById("bodyParam");
      element.classList.remove('mobileview');
  }


  render() {
     localStorage.setItem("level",this.props.learnerProfile?.classs?.level)
     localStorage.setItem("dashboard_type",this.porps?.learnerProfile?.institute_settings?.dashboard_type)
     const isEnabled = !this.state.password || !this.state.new_password || !this.state.confirm_password || !this.state.is_password_correct;
      
    return (

      <div>
      <Popup open={this.state.open1} closeOnDocumentClick onClose={this.close}
          className="change-password">
          <span className="close" onClick={this.close}>&#x2715;</span>
          <div className="change-password-notification-popup">
            
            <b>Hello</b> {this.state?.profile?.name},<br/>
            Welcome onboard<br/><br/>
            Do you want to change your Password?
          </div>
           <div Style="display: flex;width: 100%;">
            <div Style="width: 60%"> 
              <img src={ForgotImage} alt="change-password" Style="width: 106%;"/>
            </div>
            <div Style="padding-top:77px;">
              {<div className="fw_input fw_input2">
                
                <div className="input_field">
                   <input type={this.state.hide ? "password" : "text"}  placeholder='Current Password' name='password'
                    value={this.state.password} onKeyPress={this.handleKeyPress2}
                    onChange={this.handleChangePassword}/>
                  <div className="togglePassword" onClick={this.hideChange}>
                    {this.state.hide === true ? <img src={Hide} alt="see"/> 
                      : <img src={See} alt="hide"/>}
                  </div>
                  <div className="textContent"><i>password has been sent to your Email id</i></div>
                </div>
                <div className="input_field">
                   <input type={this.state.hide1 ? "password" : "text"} placeholder='New Password' name='new_password'
                    value={this.state.new_password} onKeyPress={this.handleKeyPress2}
                    onChange={this.handleChangeNewPassword}/>
                    <div className="togglePassword" onClick={this.hideChange1}>
                    {this.state.hide1 === true ? <img src={Hide} alt="see"/> 
                      : <img src={See} alt="hide"/>}
                  </div>
                </div>

                <div className="input_field" Style={this.state.is_password_correct?"border-bottom: 1px solid green":"border-bottom: 1px solid red"}>
                   <input type={this.state.hide2 ? "password" : "text"} placeholder='Confirm Password' name='confirm_password'
                    value={this.state.confirm_password} onKeyPress={this.handleKeyPress2}
                    onChange={this.handleChangeConfirmPassword}/>
                  <div className="togglePassword" onClick={this.hideChange2}>
                    {this.state.hide2 === true ? <img src={Hide} alt="see"/> 
                      : <img src={See} alt="hide"/>}
                  </div>
                </div>
                
                <div className="button-class">
                  <button onClick={() => this.changePassword(this.state.uname,this.state.password,this.state.new_password)} disabled={ isEnabled ? "disabled":""}>
                    Change Password
                  </button>
                </div>
              </div>}
            </div>
           </div>
        </Popup>

        <Popup open={this.state.open5} closeOnDocumentClick onClose={this.close6} className="complete-details">
          <div>
            <div className="content-state">
              Hey {this.state.profile?.name}, <br/><br/>

              {this.props.dashboard_type == 'dynamic' ? "Welcome to the Design Thinking and Innovation program for teachers brought to you by CBSE and AICTE, to set the pace and to help you with course better, we have planned a short pre - assessment for you, we highly recommend that you take the assessment now!":"Your coach is trying to reach out to you, for your career path. Please share your details to create a bridge between your career path and your personal coach.Click 'ok' to update."}
              
            </div>
            <div className="content-button">
              {this.props.dashboard_type == 'dynamic' ?<button onClick={this.goToAssessment} className="go-to-assessment">Go to Assessment</button>:<button onClick={this.show2}>Ok</button> }
              <button className="go-to-assessment" onClick={this.close6}>Cancel</button>
            </div>
          </div>
        </Popup>

      <div className="bottomSidebar">
        <a href="javascript:void(0);" className="toggleMenu" onClick={()=>this.menuToggleActions()}>
            <span></span>
            <span></span>
            <span></span>
        </a>
      </div>


      <div  className={this.state.mobilenavigation ? 'header2 mobilenavigationActive':this.props.learnerProfile?.institute_settings?.dashboard_type == 'dynamic' ? 'header2 new-dashboard-navigation':"header2"}>
        <div className="CloseLink"><a href="javascript:void(0)" onClick={()=>this.menuToggleClose()}>&#x2715;</a></div>
        {this.state.getCenterId == 49 && 
            <div className="sc-logo-div"><img src={vguLogo} alt="logo"/></div>
          }
        <div className="sc-logo-div-up">
          {this.state.image === null || this.state.image === ''  && this.state.getCenterId != 49  ? <div className="sc-logo-div">
          {/* <img src={require("../../public/images/"+config.brandLogo)} alt="Brand logo"/> */}
          {localStorage.getItem('logo_url') != 'undefined' ? 
          <img src={localStorage.getItem('logo_url')} alt="Brand logo"/>
          :
          <img src={require("../../public/images/"+config.brandLogo)} alt="Brand logo"/> 
          }
          </div> : <div className="sc-logo-div">
          {/* <img src={require("../../public/images/"+config.brandLogo)} alt="Brand logo"/> */}
          <img src={localStorage.getItem('logo_url')} alt="Brand logo"/>
          </div>}
        </div>
        {/* <div className="sidebar-links">
          <Link to={'/learner'} onClick={this.changeTab} id={this.props.currentPage === '/learner' 
            || this.props.currentPage === '/learner/' ? 'selected-sl': ''}>
            <img src={Dashboard} alt="dash"/>
            <span>Dashboard</span>
          </Link> */}
          {/* <Link to={'/learner/practice'} onClick={this.changeTab} id={this.props.currentPage === '/learner/practice' 
            || this.props.currentPage === '/learner/practice/' ? 'selected-sl': ''}>
            <img src={Practice} alt="dash"/>
            <span>Practice</span>
          </Link> */}
          {/* <Link to={'/learner/tests'} onClick={this.changeTab} id={this.props.currentPage === '/learner/tests' 
            || this.props.currentPage === '/learner/tests/' ? 'selected-sl': ''}>
            <img src={Tests} alt="dash"/>
            <span>Tests</span>
          </Link> */}
          {/* <Link to={'/learner/sessions'} onClick={this.changeTab} id={this.props.currentPage === '/learner/sessions' 
            || this.props.currentPage === '/learner/sessions/' ? 'selected-sl': ''}>
            <img src={Sessions} alt="dash"/>
            <span>Sessions</span>
          </Link> */}
          {/* <Link to={'/learner/doubts'} onClick={this.changeTab} id={this.props.currentPage === '/learner/doubts' 
            || this.props.currentPage === '/learner/doubts/' ? 'selected-sl': ''}>
            <img src={Doubts} alt="dash"/>
            <span>Discussions</span>
          </Link> */}
            {/*<span className="menu-icon"><img src={Dashboard} alt="dash"/></span>
            <span className="menu-text">Dashboard</span>
          </Link>
          <Link to={'/learner/practice'} onClick={this.changeTab} id={this.props.currentPage === '/learner/practice' 
            || this.props.currentPage === '/learner/practice/' ? 'selected-sl': ''}>
            <span className="menu-icon"><img src={Practice} alt="practice"/></span>
            <span className="menu-text">Practice</span>
          </Link>
          <Link to={'/learner/tests'} onClick={this.changeTab} id={this.props.currentPage === '/learner/tests' 
            || this.props.currentPage === '/learner/tests/' ? 'selected-sl': ''}>
            <span className="menu-icon"><img src={Tests} alt="tests"/></span>
            <span className="menu-text">Tests</span>
          </Link>
          <Link to={'/learner/sessions'} onClick={this.changeTab} id={this.props.currentPage === '/learner/sessions' 
            || this.props.currentPage === '/learner/sessions/' ? 'selected-sl': ''}>
            <span className="menu-icon"><img src={Sessions} alt="sessions"/></span>
            <span className="menu-text">Sessions</span>
          </Link>
          <Link to={'/learner/webinar'} onClick={this.changeTab} id={this.props.currentPage === '/learner/webinar' 
            || this.props.currentPage === '/learner/webinar/' ? 'selected-sl': ''}>
            <span className="menu-icon"><img src={Sessions} alt="webinar"/></span>
            <span className="menu-text">Webinar</span>
          </Link>
          <Link to={'/learner/doubts'} onClick={this.changeTab} id={this.props.currentPage === '/learner/doubts' 
            || this.props.currentPage === '/learner/doubts/' ? 'selected-sl': ''}>
            <span className="menu-icon"><img src={Doubts} alt="doubts"/></span>
            <span className="menu-text">Doubts</span>
          </Link>
          <Link to={'/learner/assignment'} onClick={this.changeTab} id={this.props.currentPage === '/learner/assignment' 
            || this.props.currentPage === '/learner/assignment/' ? 'selected-sl': ''}>
            <span className="menu-icon"><img src={Assignment} alt="dash"/></span>
            <span className="menu-text">Assignments</span>
          </Link>
          <Link to={'/learner/free-courses'} onClick={this.changeTab} id={this.props.currentPage === '/learner/free-courses' 
            || this.props.currentPage === '/learner/free-courses/' ? 'selected-sl': ''}>
            <span className="menu-icon"><img src={Library} alt="dash"/></span>
            <span className="menu-text">Library</span>
          </Link>
          
          {/*<Link to={'/learner/calendar'} onClick={this.changeTab} id={this.props.currentPage === '/learner/calendar' 
            || this.props.currentPage === '/learner/calendar/' ? 'selected-sl': ''}>
            <img src={Calendar} alt="dash"/>
            Calendar
          </Link>*/}
          
          {this.props.learnerProfile?.institute_settings?.dashboard_type == 'dynamic' ? <div className="sidebar-links new-dashboard-navigation dynamic-menu-config"> {this.props.learnerProfile.institute_settings.menu.sort(order => parseInt(order.order)-parseInt(order.order)).map( mn =>
            <Link to={mn.url} onClick={this.changeTab} id={this.props.currentPage === '/learner' 
              || this.props.currentPage === '/learner/' ? 'selected-sl': ''}>
              <span className="menu-icon"><img src={mn.image ? require('../../public/icons/'+mn.image) : ""} alt="dash"/></span>
              <span className="menu-text">{mn.label}</span>
            </Link>
          )}</div>:<div className="sidebar-links new-dashboard-navigation">
          <Link to={'/mycoach'} onClick={this.changeTab} id={this.props.currentPage === '/app/mycoach' 
            || this.props.currentPage === '/app/mycoach/' ? 'selected-sl': ''}>
            <img src={xpSidebarMyCoachIcon} alt="My Coach"/>
            <span>My Coach</span>
          </Link>

          {this.state.profile.profile_type != 2 && <Link to={'/mycoach/challenges'} onClick={this.changeTab} id={this.props.currentPage === '/app/mycoach/challenges' 
            || this.props.currentPage === '/app/mycoach/challenges' ? 'selected-sl': ''}>
            <img src={xpSidebarChallengesIcon} alt="challenges"/>
            <span>Challenges</span>
          </Link>}
          {this.state.profile.profile_type != 2 && <Link to={'/mycoach/certificationacademy'} onClick={this.changeTab} id={this.props.currentPage === '/app/mycoach/certificationacademy' 
            || this.props.currentPage === '/app/mycoach/certificationacademy' ? 'selected-sl': ''}>
            <img src={xpSidebarAcademyicon} alt="certificationacademy"/>
            <span>Certification Academy</span>
          </Link>}
          {this.state.profile.profile_type != 2 && <Link to={'/mycoach/events'} onClick={this.changeTab} id={this.props.currentPage === '/app/mycoach/challeeventsnges' 
            || this.props.currentPage === '/app/mycoach/events' ? 'selected-sl': ''}>
            <img src={xpSidebarEventsIcon} alt="events"/>
            <span>Events</span>
          </Link>}
          {this.state.profile.profile_type != 2 && <Link to={'/mycoach/liveprojects'} onClick={this.changeTab} id={this.props.currentPage === '/app/mycoach/liveprojects' 
            || this.props.currentPage === '/app/mycoach/liveprojects' ? 'selected-sl': ''}>
            <img src={xpSidebarLiveProjectsIcon} alt="liveprojects"/>
            <span>Live Projects</span>
          </Link>}
          {this.state.profile.profile_type != 2 && <Link to={'/mycoach/my-courses'} onClick={this.changeTab} id={this.props.currentPage === '/app/mycoach/my-courses' 
            || this.props.currentPage === '/app/mycoach/my-courses' ? 'selected-sl': ''}>
            <img src={xpSidebarAcademyicon} alt="my courses"/>
            <span>Certification Courses</span>
          </Link>}
           <Link to={'/learner/doubts'} onClick={this.changeTab} id={this.props.currentPage === '/app/learner/doubts' 
            || this.props.currentPage === '/app/learner/doubts/' ? 'selected-sl': ''}>
            <img src={xpSidebarIdeasSpaceIcon} alt="dash"/>
            <span>Idea Space</span>
          </Link>
          {/* <Link to={'/learner/noticeboard-List/'} onClick={this.changeTab} id={this.props.currentPage === '/app/learner/noticeboard-List/' 
            || this.props.currentPage === '/app/learner/noticeboard-List/' ? 'selected-sl': ''}>
            <img src={xpSidebarIdeasSpaceIcon} alt="dash"/>
            <span>Noticeboard</span>
          </Link> */}
          <Link to={'/learner/queries'} onClick={this.changeTab} id={this.props.currentPage === '/app/learner/queries' 
            || this.props.currentPage === '/app/learner/queries/' ? 'selected-sl': ''}>
            <img src={xpSidebarQueryIcon} alt="query"/>
            <span>Queries</span>
          </Link>
          <Link to={'/mycoach/stationaryshop'} onClick={this.changeTab} id={this.props.currentPage === '/app/mycoach/stationaryshop' 
            || this.props.currentPage === '/app/mycoach/stationaryshop' ? 'selected-sl': ''}>
            <img src={xpSidebarStationaryShopIcon} alt="stationaryshop"/>
            <span>Stationary Shop</span>
          </Link>
          {this.state.profile.profile_type != 2 && <Link to={'/mycoach/mypercentage'} onClick={this.changeTab} id={this.props.currentPage === '/app/mycoach/mypercentage' 
            || this.props.currentPage === '/app/mycoach/mypercentage' ? 'selected-sl': ''}>
            <img src={xpSidebarMyPercentageIcon} alt="mypercentage"/>
            <span>My Percentage</span>
          </Link>}
          <Link to={'/learner/study-material/173'} onClick={this.changeTab} id={this.props.currentPage === '/app/learner/study-material/173' 
            || this.props.currentPage === '/app/learner/study-material/173' ? 'selected-sl': ''}>
            <img src={xpSidebarTVIcon} alt="IXP TV"/>
            <span>IXP TV</span>
          </Link></div>}
          {/* <a href="javascript:void(0)" title="Coming soon" alt="Coming soon" className="is_disabled">
            <img src={xpSidebarTVIcon} alt="TV"/>
            <span>T.V</span>
          </a> */}
          {/* <Link to={'/learner/webinar'} onClick={this.changeTab} id={this.props.currentPage === '/app/learner/webinar' 
            || this.props.currentPage === '/learner/webinar/' ? 'selected-sl': ''}>
            <img src={Sessions} alt="dash"/>
            Webinar
          </Link> */}
        
        {/* <div className="brand">
          <a className="logo-sow" href={this.state.redirectURL}>
            <img src={Logo} alt="img"/>
            <span>Virtual University</span>
          </a>
        </div> */}
      </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    currentPage: state.commonReducer.currentPage,
    loading: state.commonReducer.loading,
    learnerProfile: state.classroomReducer.learnerprofile,
    dashboard_type:state.commonReducer.dashboard_type
  }
}
  
const mapDispatchToProps = (dispatch) => {
  const { setCurrentPage, getProfile,changePassword,userLogin} = actions;
  return {
    setCurrentPage: (currentPage) => dispatch(setCurrentPage(currentPage)),
    getProfile: (center_id) => dispatch(getProfile(center_id)),
    changePassword: (username,otp,password,language) => dispatch(changePassword(username,otp,password,language)),
    userLogin:(username, password,is_xp_signup) => dispatch(userLogin(username, password,is_xp_signup))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarLearner);
