import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import actions from '../../redux/actions';

import Header from '../../components/navigation/Header';
import Add from '../../public/icons/add.svg';
import List from '../../public/icons/list.svg';
import Grid from '../../public/icons/grid.svg';
import Edit from '../../public/icons/edit.svg';
import Delete from '../../public/icons/delete.svg';
import reportsLoader from '../../public/icons/loader.png';
import './AttendanceReport.css';
import Calendar from '../../public/icons/calendar2.svg';

const CreateNoticeBoard = (props) => {
    const { getNoticeBoardList, createNoticeBoard, deleteNoticeBoardList, getClassList, getSectionList2, getSubjectList2 } = props;

    const [noticeBoardList, setNoticeBoardList] = useState([]);
    const [title, setTitle] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [description, setDescription] = useState('');
    const [centerId, setCenterId] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [noticeUrl, setNoticeUrl] = useState('')
    const [noticeType, setNoticeType] = useState('')
    const [isPin, setIsPin] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [noticeToDelete, setNoticeToDelete] = useState(null);
    const [noticeId, setNoticeId] = useState(0);
    const [selectedClass, setSelectedClass] = useState('');
    const [selectedBatch, setSelectedBatch] = useState('');

    const [classList, setClassList] = useState([]);
    const [batchList, setBatchList] = useState([]);
    const [subjectList, setSubjectList] = useState([]);

    const getCenterID = localStorage.getItem("center_id");

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'application/pdf') {
            setSelectedFile(file);
        } else {
            alert("Please upload a valid PDF file.");
        }
    };

    const handleSubmit = async () => {
        if (!title || !description || !selectedFile) {
            alert('All fields are required.');
            return;
        }

        setLoading(true);
        try {
            await createNoticeBoard(title, description, selectedFile, getCenterID, startDate, endDate, isPin, noticeUrl, noticeType, selectedClass, selectedBatch);
            setIsModalOpen(false);
            // Reset form fields
            setTitle('');
            setDescription('');
            setCenterId('');
            setSelectedFile(null);
            fetchNoticeBoardList();
        } catch (error) {
            console.error('Upload failed:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchClassList = async () => {
            try {
                const response = await getClassList();
                setClassList(response.data);
            } catch (error) {
                console.error("Failed to fetch class list:", error);
            }
        };
        fetchClassList();
    }, [getClassList]);

    const fetchNoticeBoardList = async () => {
        try {
            const response = await getNoticeBoardList(getCenterID, selectedClass, selectedBatch);
            if (response && response.data) {
                setNoticeBoardList(response.data);
            } else {
                console.error('Unexpected response structure:', response);
            }
        } catch (error) {
            console.error('Failed to fetch noticeboard list:', error);
        }
    };

    useEffect(() => {
        fetchNoticeBoardList();
    }, [noticeId, isModalOpen, selectedClass, selectedBatch]);

    const deleteNoticeBoard = async (id) => {
        try {
            await deleteNoticeBoardList(id);
            fetchNoticeBoardList();
        } catch (error) {
            console.error('Failed to delete notice:', error);
        }
    };

    const handleDeleteClick = (notice) => {
        setNoticeToDelete(notice);
        setIsDeleteModalOpen(true);
    };

    const formatDateTime = (dateTimeString) => {
        const date = new Date(dateTimeString);
        return date.toLocaleDateString('default', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        });
    }

    const confirmDelete = () => {
        if (noticeToDelete) {
            deleteNoticeBoard(noticeToDelete.id);
            setIsDeleteModalOpen(false);
            setNoticeToDelete(null);
        }
    };

    const onClassChange = async (e) => {
        const selectedClassId = e.target.value;
        setSelectedClass(selectedClassId);
        try {
            const response = await getSectionList2(3, selectedClassId);
            setBatchList(response.data);
        } catch (error) {
            console.error("Failed to fetch batch list:", error);
        }
    };

    const onBatchChange = async (e) => {
        const selectedBatchId = e.target.value;
        setSelectedBatch(selectedBatchId);
        try {
            const response = await getSubjectList2(3, selectedClass);
            setSubjectList(response.data);
        } catch (error) {
            console.error("Failed to fetch subject list:", error);
        }
    };

    const handleEditClick = (notice) => {
        setTitle(notice.title);
        setDescription(notice.description);
        setCenterId(notice.centerId);
        setSelectedFile(notice.selectedFile);
        setNoticeId(notice.id);
        setStartDate(notice.startDate || '');
        setEndDate(notice.endDate || '');
        setNoticeUrl(notice.noticeUrl || '');
        setNoticeType(notice.noticeType || '');
        setIsPin(notice.isPin || false);
        setIsModalOpen(true);
    };

    return (
        <div className="admin-div">
            <Header placeholder="Search classes" />
            <div className="main-part">
                <div className="sub-head">
                    <div className="heading-part1">
                        <div className="heading">
                            <h1>Noticeboard</h1>
                        </div>
                    </div>
                    <div className="heading-part2">
                        <span className="add-new-button" onClick={() => setIsModalOpen(true)}>
                            <img src={Add} alt="Add" /> Add New
                        </span>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                    <div className="dropdown-container">
                        <select onChange={onClassChange} value={selectedClass}>
                            <option value="">Select Class</option>
                            {classList.map(classs => (
                                <option value={classs.id} key={classs.id}>
                                    {classs.label}
                                </option>
                            ))}
                        </select>

                        <select onChange={onBatchChange} value={selectedBatch}>
                            <option value="">Select Batch</option>
                            {batchList && batchList.map(batchItem => (
                                <option value={batchItem.id} key={batchItem.id}>
                                    {batchItem.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>



                <div className="noticeboard-list" style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', padding: '20px 0' }}>
                    {noticeBoardList.length === 0 ? (
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            {/* <img src={reportsLoader} alt="Loading..." /> */}
                            <h1 style={{ paddingTop: '200px' }}>Not found! Please Select the batch</h1>
                        </div>
                    ) : (
                        noticeBoardList.map((notice, index) => (
                            <div key={index} className="card" style={{ width: '400px', border: '1px solid #ddd', borderRadius: '8px', overflow: 'hidden' }}>
                                <div className="card-content" style={{ padding: '15px' }}>
                                    <h3 style={{ marginBottom: '10px' }}>{notice.title}</h3>
                                    <p style={{ marginBottom: '10px' }}>{notice.description}</p>
                                    <div className="date-container" style={{ display: 'flex', flexDirection: 'row', gap: '5px', marginBottom: '10px' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                            <img src={Calendar} alt="Calendar" style={{ height: '16px', width: '16px' }} />
                                            <span>{formatDateTime(notice.start_date_time)}</span>
                                            <p>To</p>
                                        </div>

                                        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                            {/* <img src={Calendar} alt="Calendar" style={{ height: '16px', width: '16px' }} /> */}
                                            <span>{formatDateTime(notice.end_date_time)}</span>
                                        </div>
                                    </div>
                                    <div className="card-actions" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <a href={notice.file} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#2DA77D' }}>
                                            View Notice
                                        </a>

                                        <div>
                                            <button onClick={() => handleEditClick(notice)} style={{ background: 'none', border: 'none', cursor: 'pointer', marginRight: '10px' }}>
                                                <img src={Edit} alt="Edit" style={{ width: '16px', height: '16px' }} />
                                            </button>
                                            <button onClick={() => handleDeleteClick(notice)} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                                                <img src={Delete} alt="Delete" style={{ width: '16px', height: '16px' }} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>

            {isModalOpen && (
                <div className="modal" style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, background: 'rgba(0,0,0,0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="modal-content" style={{ background: 'white', padding: '20px', borderRadius: '8px', width: '80%', maxWidth: '500px' }}>
                        <h2>{noticeId ? 'Edit Noticeboard' : 'Create Noticeboard'}</h2>

                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                            <div className="dropdown-container">
                                <select onChange={onClassChange} value={selectedClass}>
                                    <option value="">Select Class</option>
                                    {classList.map(classs => (
                                        <option value={classs.id} key={classs.id}>
                                            {classs.label}
                                        </option>
                                    ))}
                                </select>

                                <select onChange={onBatchChange} value={selectedBatch}>
                                    <option value="">Select Batch</option>
                                    {batchList && batchList.map(batchItem => (
                                        <option value={batchItem.id} key={batchItem.id}>
                                            {batchItem.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <input
                            type="text"
                            placeholder="Title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            style={{ width: '100%', marginBottom: '10px', padding: '5px' }}
                        />
                        <input
                            type="text"
                            placeholder="Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            style={{ width: '100%', marginBottom: '10px', padding: '5px' }}
                        />

                        <input
                            type="date"
                            placeholder="Start Date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            style={{ width: '100%', marginBottom: '10px', padding: '5px' }}
                        />
                        <input
                            type="date"
                            placeholder="End Date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            style={{ width: '100%', marginBottom: '10px', padding: '5px' }}
                        />
                        <input
                            type="url"
                            placeholder="Notice Link"
                            value={noticeUrl}
                            onChange={(e) => setNoticeUrl(e.target.value)}
                            style={{ width: '100%', marginBottom: '10px', padding: '5px' }}
                        />
                        <input
                            type="text"
                            placeholder="Notice Type"
                            value={noticeType}
                            onChange={(e) => setNoticeType(e.target.value)}
                            style={{ width: '100%', marginBottom: '10px', padding: '5px' }}
                        />
                        <input
                            type="file"
                            accept=".pdf"
                            onChange={handleFileUpload}
                            style={{ marginBottom: '10px' }}
                        />
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '10px' }}>
                            <input
                                type="checkbox"
                                checked={isPin}
                                onChange={(e) => setIsPin(e.target.checked)}
                                style={{ marginRight: '10px' }}
                            />
                            <h4 style={{ margin: 0 }}>Is pin</h4>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                            <button onClick={() => setIsModalOpen(false)} style={{ padding: '5px 10px' }}>Cancel</button>
                            <button onClick={handleSubmit} disabled={loading} style={{ padding: '5px 10px' }}>
                                {loading ? 'Uploading...' : 'Submit'}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {isDeleteModalOpen && (
                <div className="modal" style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, background: 'rgba(0,0,0,0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="modal-content" style={{ background: 'white', padding: '20px', borderRadius: '8px', width: '80%', maxWidth: '400px' }}>
                        <h2>Confirm Deletion</h2>
                        <p>Are you sure you want to delete this notice?</p>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginTop: '20px' }}>
                            <button onClick={() => setIsDeleteModalOpen(false)} style={{ padding: '5px 10px' }}>Cancel</button>
                            <button onClick={confirmDelete} style={{ padding: '5px 10px', background: '#dc3545', color: 'white', border: 'none' }}>Delete</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    // Map your state to props if needed
});

const mapDispatchToProps = (dispatch) => {
    const { getNoticeBoardList, createNoticeBoard,
        deleteNoticeBoardList, getClassList, getSectionList2, getSubjectList2 } = actions;
    return {
        createNoticeBoard: (title, description, file, center_id, startDate, endDate, isPin, notice_url, notice_type, class_id, batch_id) =>
            dispatch(createNoticeBoard(title, description, file, center_id, startDate, endDate, isPin, notice_url, notice_type, class_id, batch_id)),
        getNoticeBoardList: (center_id, class_id, batch_id) => dispatch(getNoticeBoardList(center_id, class_id, batch_id)),
        deleteNoticeBoardList: (id) => dispatch(deleteNoticeBoardList(id)),
        getClassList: () => dispatch(getClassList()),
        getSectionList2: (center_id, class_id) => dispatch(getSectionList2(center_id, class_id)),
        getSubjectList2: (center_id, class_id) => dispatch(getSubjectList2(center_id, class_id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNoticeBoard);