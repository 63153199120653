
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { useLocation } from 'react-router-dom';


const ReactVideoPlayer = (props) => {

    const location = useLocation();
    const { selectedVideo } = location.state || {};  // Retrieve the state passed in Link

    const [videoUrl, setVideoUrl] = useState(selectedVideo.replace('veranda-1-prod-lms-videos.s3.ap-south-1.amazonaws.com', 'verandacdn.verandahighered.com'));

    return (

        <div className='app-body'>
          
            <div className='app-div'>
                <button style={{ marginBottom: '10px' }} onClick={() => window.history.back()}>Back</button>
         
                {

                   
                    selectedVideo.includes('.mp4') ? <ReactPlayer
                        url={videoUrl}
                        controls={true}
                        width="100%"
                        height="586px"
                        className="react-player"
                        autoplay={true}
                        onError={() => {
                            console.log('Error playing video');
                            setVideoUrl(selectedVideo);
                        }}
                    /> : <iframe src={selectedVideo} width="100%" height="586px" />
                }

            </div>
        </div>
    );
};

export default ReactVideoPlayer;