import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Popup from 'reactjs-popup';
import { Helmet } from "react-helmet";
import ReactNotifications from 'react-notifications-component';
import Shimmer from 'react-js-loading-shimmer';
import { Link } from 'react-router-dom';
import actions from '../../redux/actions';
import Add from '../../public/icons/add.svg';
import List from '../../public/icons/list.svg';
import Grid from '../../public/icons/grid.svg';
import Edit from '../../public/icons/edit.svg';
import Delete from '../../public/icons/delete.svg';
import Trash from '../../public/icons/trash.svg';
import Header from '../../components/navigation/Header';
import Empty from '../../components/error/Empty';
import Back from '../../public/icons/back.svg';
import mapIcon from '../../public/icons/map-icon.svg';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import DownloadArrow from '../../public/icons/down-arrow.svg';
import sampleBatchUpdateFile from '../../public/images/bulk_batch_assign.csv';
import reportsLoader from '../../public/icons/loader.png';
import { addYears } from 'date-fns';



class ElectiveSubjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      d_id: null,
      open: false,
      open2: false,
      opend: false,
      section_id: null,
      batch_id: this.props.location.state?.batch_id,
      classs: parseInt(this.props.match.params.classs),
      class_id: this.props.location.state?.class_id,
      name: '',
      code: '',
      order: '',
      description: '',
      page_url: this.props.location.pathname,
      class_label: this.props.location.state?.class_label,
      center_id: this.props.location.state?.center_id,
      list: localStorage.getItem('preferredGrid') === 'false' ? true : false,
      classes: [],
      sections: [],
      is_faculty_admin: false,
      session: 0,
      getlistingData: [],
      loaderShow: false,

      openDialog: false,
      openThanksDialog: false,
      groupTitle: '',
      numberOfGroups: 0,
      noOfStudents: this.props.location.state?.no_of_students,
      session_id: this.props.location.state?.session_id,
      username: localStorage.getItem('name'),
      center_id: localStorage.getItem('center_id'),
      groupPurpose: '',
      breakOutRoomName: '',
      csvFileName: '',
      csvFileOnly: '',
      groupFileError: false,
      studentList: null,
      selected_class_id: null,
      selected_section_id: null,
      removed_subjects_details: [],
      save_subject_details: [],
      removed_subjects_details_payload: [],
      save_subjects_details_payload: [],
      subjects: [],
      currentPage: 1,
      totalPage: 1,
      pageSize: 20,
      loading: false,
      checkedSubjects: [],
      uncheckedSubjects: [],
      removedSubjects: [],
    }
  }



  componentDidMount() {
    localStorage.setItem("page_url", this.state.page_url)
    this.getClassList();
  }



  getClassList = async () => {
    const response = await this.props.getClassList();
    if (response !== undefined) {
      this.setState({ classes: response?.data });

    }
  }

  handleClassChange = async (e) => {
    this.setState({ selected_class_id: e.target.value })
    const response = await this.props.getSectionList2(this.state.center_id, e.target.value)

    if (response !== undefined) {
      this.setState({ sections: response?.data });
    }
  }
  handleSectionChange = async (e) => {
    this.setState({ studentList: null, loading: true })

    this.setState({ selected_section_id: e.target.value, currentPage: 1 }, () => {

      this.fetchStudentList();
    });
    const response = await this.props.getElectiveStudentList(this.state.center_id, this.state.selected_class_id, e.target.value,)
    if (response != undefined) {
      this.setState({ studentList: response?.data?.data })
    }
    await this.getSubjectList();
    this.setState({ loading: false })
  }

  getSubjectList = async () => {
    try {
      const response = await this.props.getSubjectList(this.state.center_id, this.state.selected_class_id);
      if (response && response.data) {
        this.setState({ subjects: response.data });
      } else {
        console.error('Unexpected response structure:', response);

        this.setState({ errorMessage: 'Failed to fetch subjects.' });
      }
    } catch (error) {
      console.error('Failed to fetch subject list:', error);

      this.setState({ errorMessage: 'Error fetching subjects. Please try again.' });
    }
  };

  renderPagination() {
    const { currentPage, totalPage } = this.state;
    const pageNumbers = [];

    if (totalPage < 4) {

      for (let i = 1; i <= totalPage; i++) {
        pageNumbers.push(i);
      }
    } else {

      let startPage = Math.max(1, currentPage - 1);
      let endPage = Math.min(totalPage, currentPage + 2);


      if (endPage - startPage < 3) {
        if (startPage === 1) {
          endPage = 4;
        } else if (endPage === totalPage) {
          startPage = totalPage - 3;
        }
      }


      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
    }
    return (
      <div className="pagination" style={{ textAlign: 'center', marginRight: '20px' }}>
        {/* Backward Button */}
        <button
          className={`pagination-button ${currentPage === 1 ? 'disabled' : ''}`}
          onClick={() => this.handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          style={{ margin: '0 5px' }} // Add margin to buttons
        >
          &lt; Prev
        </button>



        {/* Page Numbers */}
        {pageNumbers.map((number) => (
          <button
            key={number}
            className={`pagination-button ${currentPage === number ? 'active' : ''}`}
            onClick={() => this.handlePageChange(number)}
            style={{ margin: '0 5px' }} // Add margin to buttons
          >
            {number}
          </button>
        ))}

        {/* Forward Button */}
        <button
          className={`pagination-button ${currentPage === totalPage ? 'disabled' : ''}`}
          onClick={() => this.handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPage}
          style={{ margin: '0 5px' }} // Add margin to buttons
        >
          Next &gt;
        </button>
      </div>
    );
  }



  handleSubjectMappingChange = (student_id, subject_id, subject_label) => {
    let { studentList, checkedSubjects, removedSubjects } = this.state;


    const updatedStudentList = studentList.map((student) => {
      if (student.id === student_id) {
        const subjectIndex = student.subjects_details.findIndex((subject) => subject.id === subject_id);

        if (subjectIndex === -1) {

          student.subjects_details.push({ id: subject_id, label: subject_label });


          const checkedEntry = checkedSubjects.find(entry => entry.student_id === student_id);
          if (checkedEntry) {
            checkedEntry.subject_ids.push(subject_id);
          } else {
            checkedSubjects.push({ student_id, subject_ids: [subject_id] });
          }


          removedSubjects = removedSubjects.filter(entry => !(entry.student_id === student_id && entry.subject_ids.includes(subject_id)));
        } else {

          student.subjects_details.splice(subjectIndex, 1);


          const removedEntry = removedSubjects.find(entry => entry.student_id === student_id);
          if (removedEntry) {
            removedEntry.subject_ids.push(subject_id);
          } else {
            removedSubjects.push({ student_id, subject_ids: [subject_id] });
          }


          checkedSubjects = checkedSubjects.filter(entry => !(entry.student_id === student_id && entry.subject_ids.includes(subject_id)));
        }
      }
      return student;
    });


    this.setState({
      studentList: updatedStudentList,
      checkedSubjects,
      removedSubjects
    });
  };



  handleSubjectMapping = async () => {
    const response = await this.props.removeElectiveStudent(this.state.removed_subjects_details_payload)

    await this.getSubjectList();

  }

  handleSaveChange = async () => {
    try {
      const { checkedSubjects, removedSubjects } = this.state;
      const savePromises = [];

      this.state.studentList.forEach((student) => {
        const checkedSubjectEntry = checkedSubjects.find((entry) => entry.student_id === student.id);
        const removedSubjectEntry = removedSubjects.find((entry) => entry.student_id === student.id);

        let map_subject_ids = [];
        let unmap_subject_ids = [];

        if (checkedSubjectEntry) {
          map_subject_ids = checkedSubjectEntry.subject_ids;
        }

        if (removedSubjectEntry) {
          unmap_subject_ids = removedSubjectEntry.subject_ids;
        }


        if (map_subject_ids.length > 0 || unmap_subject_ids.length > 0) {
          savePromises.push(
            this.props.saveElectiveStudent(student.id, map_subject_ids, unmap_subject_ids)
          );


          map_subject_ids = [];
          unmap_subject_ids = [];
        }
      });

      const responses = await Promise.all(savePromises);

      console.log("Payload:", responses);

      responses.forEach((response) => {
        if (response.status === 1) {
          console.log("Subject assignments updated successfully for student ID:", response.student_id);
        } else {
          console.error("Error while saving for student ID:", response.student_id, "Error:", response.error);
        }
      });

      await this.fetchStudentList();

    } catch (error) {
      console.error("Error updating subject assignments:", error);
    }
  };




  fetchStudentList = async () => {
    const { center_id, selected_class_id, selected_section_id, currentPage, pageSize } = this.state;

    this.setState({
      loaderShow: true,
    });
    const response = await this.props.getElectiveStudentList(center_id, selected_class_id, selected_section_id, currentPage, pageSize);
    if (response !== undefined) {

      this.setState({
        studentList: response?.data?.data,
        totalPage: response?.data?.pagination?.total_page,
        loaderShow: false,
      });
    }
  }



  handlePageChange = (pageNumber) => {

    this.setState({ currentPage: pageNumber }, () => {

      this.fetchStudentList();
    });
  }




  render() {
    const isEnabled = this.state.groupPurpose && this.state.breakOutRoomName && this.state.csvFileName && this.state.groupFileError == '';




    return (

      <div className="admin-div">
        <Helmet>
          {/* <title>{Open Grpup Listing}</title> */}
        </Helmet>
        <ReactNotifications />
        <Header placeholder={this.props.pagewiseLanguageData.search_sections} />

        <div className="main-part">
          <div className="sub-head">
            <div className="heading marginBottomZero directionChange">
              <h1>Elective Subjects</h1>
              <p>Student's subjects can be managed from this screen</p>
            </div>

          </div>

          <div className='electiveStudentsWrapper'>

            <h3>Update Student Subjects</h3>

            <div className='filtersWrapper'>
              <div className='block'>
                <label>Select class</label>
                <select onChange={this.handleClassChange}>
                  <option value="0">Select Class</option>
                  {this.state.classes.map((classs, index) =>
                    <option value={classs.id}> {classs.label}</option>
                  )};
                </select>


              </div>
              <div className='block'>
                <label>Select Section</label>
                <select onChange={this.handleSectionChange}>
                  <option value="0">Select Section</option>
                  {this.state.sections.map((section, index) =>
                    <option value={section.id}> {section.label}</option>
                  )};
                </select>
              </div>
            </div>

            <div className='studentListingBlock'>
              {/* <p>{JSON.stringify(this.state.studentList)}</p> */}

              {this.state.loaderShow && <div className='LoadingMessage'><img src={reportsLoader} /></div>}
              {this.state.loaderShow == false && this.state.studentList && <table>
                <thead>
                  <tr>
                    <th>Student Name</th>
                    {this.state.subjects.map((subject, index) => (
                      <th key={index}>{subject.label}</th>
                    ))}
                    <th><center>Action</center></th>
                  </tr>

                </thead>


                <tbody>
                  {Array.isArray(this.state.studentList) && this.state.studentList?.map((student, studentIndex) => (

                    <tr key={student?.user?.id}>
                      <td>{student?.user?.name}</td>

                      {this.state.subjects.map((subject, index) => (
                        <td key={subject.id}>
                          <input
                            type="checkbox"

                            // checked={student.subjects_details.filter((data) => data.id == subject.id).length != 0}
                            checked={student.subjects_details.some(data => data.id === subject.id)}


                            onChange={(e) => {


                              this.handleSubjectMappingChange(student?.id, subject.id, subject.label)

                            }}
                          />

                        </td>

                      ))}
                     
                        <button style= { {marginBottom:'12px', marginLeft:'60px'}} onClick={() => { this.handleSaveChange() }}>Update</button>
                     

                    </tr>
                  ))}
                </tbody>
              </table>


              }



            </div>

          </div>

        </div>
        <center style={{ marginTop: '20px' }}>{this.renderPagination()}</center>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    pagewiseLanguageData: state.commonReducer.pagewiseLanguageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { createSection, getSubjectList, updateSection, getSectionList, getClassList, getPageLanguageWiseData, breakoutRoomListing, createBreakoutRoom, getSectionList2, getElectiveStudentList, removeElectiveStudent, saveElectiveStudent } = actions;
  return {
    getSectionList: (center_id, class_id, show_all, page) => dispatch(getSectionList(center_id, class_id, show_all, page)),
    getClassList: () => dispatch(getClassList()),
    getPageLanguageWiseData: (language, page) => dispatch(getPageLanguageWiseData(language, page)),
    getSectionList2: (center_id, class_id) => dispatch(getSectionList2(center_id, class_id)),
    getElectiveStudentList: (center_id, class_id, section_id, page, pageSize) => dispatch(getElectiveStudentList(center_id, class_id, section_id, page, pageSize)),
    removeElectiveStudent: (student_list) => dispatch(removeElectiveStudent(student_list)),
    saveElectiveStudent: (student_id, map_subject_ids, unmap_subject_ids) => dispatch(saveElectiveStudent(student_id, map_subject_ids, unmap_subject_ids)),
    getSubjectList: (center_id, class_id,) => dispatch(getSubjectList(center_id, class_id,)),


  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ElectiveSubjects);





