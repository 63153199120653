
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import './VideoList.css';

const videoUrls = [
    'https://veranda-1-prod-lms-videos.s3.ap-south-1.amazonaws.com/Public-Content/2024/8/18/vw9dw2l5ov/vw9dw2l5ov_sastra_-_evab_platform_familiarization_session_at_9_pm_(17th_sep%2C_2024).mp4',
    'https://veranda-1-prod-lms-videos.s3.ap-south-1.amazonaws.com/Public-Content/2024/0/20/5wm8kdyjct/5wm8kdyjct_sastra_mba_b1_sem2_master_class_byprof.mp4',
];

const VideoList = () => {
    const [selectedVideo, setSelectedVideo] = useState(videoUrls[0]);

    return (

        <div className='app-body'>
            <div className='app-div'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="sidebar">
                                <h2 className="mb-3">Video List</h2>
                                <ul className="list-group">
                                    {videoUrls.map((url, index) => (
                                        <li key={index} 
                                            className="list-group-item list-group-item-action" 
                                            onClick={() => setSelectedVideo(url)}>
                                            Video {index + 1}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="main-content">
                                <ReactPlayer
                                    url={selectedVideo}
                                    controls={true}
                                    width="100%"
                                    height="500px"
                                    className="react-player"
                                    autoplay={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VideoList;