import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import { Helmet } from "react-helmet";
import { DragDropContext, Droppable,Draggable } from 'react-beautiful-dnd';
// import MathJax from 'react-mathjax';
import ReactNotifications, { store } from 'react-notifications-component';
import Popup from 'reactjs-popup';
import Trash from '../../public/icons/trash.svg';
import common from '../../common';
import actions from '../../redux/actions';
import MoveRight from '../../public/icons/move-right.svg';
import MoveLeft from '../../public/icons/move-left.svg';
import Left from '../../public/icons/left-tray.svg';
import Right from '../../public/icons/right-tray.svg';
import Zoom from '../../public/icons/full-screen.svg';
import download from '../../public/icons/down-arrow.svg';
import Delete from '../../public/icons/delete.svg';
import Library from '../../public/icons/books.png';
import Slider from "react-slick";
import subscribeicon from '../../public/icons/mycoach-subscribed-icon.png';
import PaidCourse from '../../public/icons/paid_course_csk.png';
import 'react-notifications-component/dist/theme.css';
import './styles.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import Widget from './widgetSetting';
import arrowicon from '../../public/icons/mycoach-arrow-icon.png';
import ColorPicker from './colorPicker';
import WidgetPreview from './WidgetPreview';
import { Button,Modal} from 'react-bootstrap';
import MultiSelect from "react-multi-select-component";
import settingicon from '../../public/icons/settings.svg';
import tickicon from '../../public/icons/iconfinder_Tick_Mark_Circle_1398913.png';
import editicon from '../../public/icons/editicon.png';
import addicon from '../../public/icons/insertrow-icon.png';
import addwidget from '../../public/icons/insertwidget-icon.png';
import './widget-styles.css';

const quickLinkList = [
  {
    label: 'Assignments',
    value:'assignment',
    "disabled":false
  },
  {
    label: 'Assessments',
    value:'assessment',
    "disabled":false
  },
  {
    label: 'LiveClass',
    value:'live_class',
    "disabled":false
  },
  {
    label: 'Notes',
    value:'notes',
    "disabled":false
  },
  {
    label: 'Videos',
    value:'video',
    "disabled":false
  },
]


const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1
};

/*const pageUrlLists = [
  {label":"dashboard","value":"learner/widget-setting/1","disabled":false},
  {"label":"question","value":"learner/widget-setting/1","disabled":false},
  {"label":"wenibar","value":"learner/widget-setting/1","disabled":false},
]*/

const pageUrlLists = [
    { label: "dashboard", value: "learner/widget-setting/1" },
    { label: "question", value: "learner/widget-setting/22" },
    { label: "wenibar", value: "learner/widget-setting/3", disabled: false },
]

const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

const validMobileRegex = /^[0-9\b]+$/;

class widgetSettingClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      center_id: localStorage.getItem('center_id'),
      id: this.props.match.params.id,
      exerciseAttempt: {},
      title: '',
      code: '',
      no_ques: false,
      sections: [],
      selectedPage:[],
      questions: [],
      currentSection: '',
      currentQuestion: '',
      section_title: '',
      question_title: '',
      imageposition:'',
      display_in_seconds:'',
      repeat:'',
      clickable_area:'',
      placeholders:[],
      is_button:'',
      is_new_login:'',
      page_url:[],
      access_type:'',
      selected_widget_id:'',
      imagetype:'',
      options: [],
      selected_image_on:null,
      file_extension:"",
      backgroundColor:'#223d35',
      borderRadius:'',
      fontWeight:'',
      fontSize:'',
      textAlign:'',
      is_preview:0,
      show:false,
      widgetMasterList:[],
      widget_type:'',
      position:'',
      size:'',
      settings:{},
      cssState:{writable: true},
      classState:{},
      configState:{"heading":"","image":"","link":""},
      bannerList:[],
      selectBanner:"none",
      selectForm:"none",
      selected:[],
      WidgetColumnList:[],
      selectCourse:'none',
      selectQuickLinks:"none",
      addClass: false,
      addRow:false,
      rowData:[],
      rowHeading:'',
      widgetRows:[],
      widget_title:'',
      size:'',
      selected_quick_links:[],
      widgetList:[],
      widgetRows:[],
      widgetIds :[],
      widgetdata:{},
      widgetSettings:[],
      widgetSettingsCss:[],
      widgetSettingsConfig:[],
      questions:[],
      open_refer:false,
      isEnabled:false,
      selected_list_id:null,
      widget_form_data:[],
      username:localStorage.getItem("name"),
      showModal: false,
      is_checked:false,
      onsave:false,
      errors:{},
      fields:{},
      formList:[],
      deleteWidget:false,
      CompleteWidgetArray:[],
      bannerStruct:{
        banner_title:"",
        banner_description:"",
        banner_url:"",
        banner_level:"",
        banner_image:"",
        start_date_time:"",
        end_date_time:"",
        banner_type:"",
        banner_image_url:""
      },
      formStruct:{
        form_type:"",
        form_description:"",
        form_title:"",
        success_message:""
      },
      addBanner:false,
      slidersettings : {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll:2,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 770,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false
            }
          }
        ]
      }
    }
    this.node = React.createRef();
    this.node2 = React.createRef();
    this.node3 = React.createRef();
  }

  componentDidMount() {
    //this.getAssessment(this.props.match.params.id);
    this.widgetList();
    this.instituteWidgetList();
    this.getCertificationBannerList('');
    this.formList();

    /*setInterval(() => {
      this.setState({is_preview:1})}, 1000)*/

  }

  widgetList = async () => {
    const response = await this.props.widgetList();
    if(response !== undefined) {
      this.setState({ widgetMasterList: response.data?.filter(p => p.type !='row') });
    }
  }

  formList = async () => {
    const response = await this.props.formList();
    if(response !== undefined) {
      this.setState({ formList: response.data});
    }
  }

  instituteWidgetList = async() =>{

    let {widgetRows,slidersettings,widgetList,widgetdata,widgetIds,widgetSettings,widgetSettingsCss,widgetSettingsConfig} = this.state

    const response = await this.props.instituteWidgetList()
    if(response != undefined){

      let widgetData = response.data?.filter(w => w.type !='popup' && w.type !='row').sort((a,b) => parseInt(a.column) - parseInt(b.column))

      widgetRows = response.data?.filter(wr => wr.type =='row').sort((a,b) => parseInt(a.row) - parseInt(b.row))

      widgetIds = response.data.map(list => list.id)

      /*****************************/

      let courseWidget = response.data.filter(w => w.type == 'courses').map(cr => {
        if(cr.data.type == 2) {

          if(cr.size == "1x1"){
            slidersettings.slidesToShow = 1
            this.setState({slidersettings})
          } 
          if(cr.size == "1x2"){
            slidersettings.slidesToShow = 2
            this.setState({slidersettings})
          } 
          if(cr.size == "1x3"){
            slidersettings.slidesToShow = 3
            this.setState({slidersettings})
          } 
        }
      })

      console.log(courseWidget)
      /*****************************/

      for(let wd = 1;wd <= widgetRows.length;wd++){

        widgetList[wd] = widgetData?.filter(w => parseInt(w.row) === parseInt(wd))                   

      }

      response.data.forEach((data, index, array) => {
          widgetSettingsCss[data.id] = data.settings.settings?.css
      });
      //widgetSettingsConfig = response.data.map(list1 => list1.settings.settings?.config)
      //var widgetSettingsConfig = [];
      response.data.forEach((data, index, array) => {
          widgetSettingsConfig[data.id] = data.settings.settings?.config == undefined ? {}:data.settings.settings?.config
      });

      response.data.forEach((data, index, array) => {
          let wdgtsetting = data.settings.settings?.class

          let sett = [];
          if(wdgtsetting){
            for(const [key,value] of Object.entries(wdgtsetting)){
              sett.push(key+"-"+value)
            }
            widgetSettings[data.id] = sett.join(" ")
          }
      });
    }
    if(widgetIds && widgetIds.length){

      for(let i=0; i<= widgetIds.length ; i++){
        let widget_response = await this.props.getWidgetDataById(widgetIds[i]);
        if(widget_response != undefined){
          if(widget_response.data !=undefined)
            widgetdata[widgetIds[i]] = widget_response.data
        }
      }
    }


      widgetdata[12] = [
                        {
                          batch_id: 150,
                          batch_label: "CSK_BATCH 1",
                          description: "A Future Skill Readiness bridge for 12th Class Students to have a breakthrough  Career Journey",
                          id: 79,
                          label: "ImaginXP Future-Ready School-to-University Programme",
                          level: 1,
                        },
                        {
                          batch_id: 168,
                          batch_label: "ImaginXP Future-Ready Talent Assessment batch Session 2021",
                          description: "A Future Skill Assessment for 12th Class Students for admission in Future skill Universities.",
                          id: 88,
                          label: "ImaginXP Future-Ready Talent Assessment",
                          level: 12,
                        }
                      ]
    widgetdata[18] = {live_class:[
                        {
                            action_label: "Scheduled",
                            attendance_status: 2,
                            batch: "CSK_BATCH 1",
                            batch_attendance: "",
                            batch_strength: 215,
                            center: {id: 7, added_on: "2021-05-24T10:48:42.069658", updated_on: "2021-05-24T10:48:42.069699"},
                            chapter_id: 0,
                            chapter_title: "",
                            classs: "ImaginXP Future-Ready School-to-University Programme",
                            duration_hrs: 2,
                            end_date_time: "2021-06-23T13:00:00",
                            etc: null,
                            faculty: "Experts",
                            id: 1992,
                            important_label: "",
                            is_delivered: false,
                            is_tentative: false,
                            is_webinar: false,
                            liveClass_server: 1,
                            live_class_Session: null,
                            mode: 2,
                            nps_score: "",
                            open_url: "https://virtualuniversity.imaginxp.live/register/Mjg3Ng==",
                            parent_lecture_id: 0,
                            phase: null,
                            profile_pic: null,
                            program: null,
                            record_available: false,
                            record_url: "https://imaginxp.congrea.net/index.php?aWQ9NzY4MSZjbGFzc19pZD0yODc2JnJvbGU9dCZjYnVybD0yJmZuYW1lPVByaXRhbSBkdXR0YSZsbmFtZT0=",
                            room: "2876",
                            sequence_no: 0,
                            session: null,
                            start_date_time: "2021-06-23T11:00:00",
                            status_color: "#f0f0f0",
                            subject: "ImaginXP's vision, mission, and values",
                            title: "ImaginXP's vision, mission, and values",
                            updated_by: null,
                            url: "https://imaginxp.congrea.net/index.php?aWQ9OTA3MyZjbGFzc19pZD0yODc2JnJvbGU9cyZjYnVybD0yJmZuYW1lPVRhcmluaSZsbmFtZT0=",
                            videos: [],
                        },
                       
                      ]}

    this.setState({CompleteWidgetArray:response.data,loading:false,widgetRows,widgetdata,widgetList,widgetSettings,widgetSettingsConfig,widgetSettingsCss})
  }

  getCertificationBannerList = async (type) => {
    const response = await this.props.getCertificationBannerList('',this.state.center_id);
    if(response !== undefined) {
      console.log(response.data)
      const bannerList = response.data.map( bnr => ({label:bnr.title,value:bnr.id,"disabled":false}))
      this.setState({ bannerList});
    }
  }

  setSelected = (selected) =>{
    this.setState({selected});
  }

  setSelectedPage = (selectedPage) =>{
    this.setState({selectedPage});
  }

  setQuickLinks =(selected) =>{
    this.setState({selected_quick_links:selected});
  }
  
  handleChangeComplete = (color) => {
    this.setState({ background: color.hex });
  };

  setBgColor  = (color) => {
    'use strict';
    let {cssState,selected_widget_id,widgetSettingsCss} = this.state
    cssState ={}
    cssState['backgroundColor'] = color

    widgetSettingsCss[selected_widget_id] = {...widgetSettingsCss[selected_widget_id],...cssState,}
    this.setState({ backgroundColor:color,cssState,widgetSettingsCss})
  }

  addNewBanner = async() =>{
    const {bannerStruct} = this.state
  
    const response = await this.props.bannerCreate(bannerStruct['banner_title'], bannerStruct['banner_description'], bannerStruct['start_date_time'], bannerStruct['end_date_time'], bannerStruct['banner_type'], bannerStruct['banner_level'], bannerStruct['banner_image'], bannerStruct['banner_url'],bannerStruct['banner_description'],"",this.state.center_id)
  
    this.setState({addBanner:false,show:true})
    this.getCertificationBannerList('',this.state.center_id)
    
  }

  setColor  = (color) => {
    'use strict';
    let {cssState,selected_widget_id,widgetSettingsCss} = this.state
    cssState ={}
    cssState['color'] = color

    widgetSettingsCss[selected_widget_id] = {...widgetSettingsCss[selected_widget_id],...cssState,}
    this.setState({ color,cssState,widgetSettingsCss})
  }

  setBorderRadius = (e) =>{
    let {cssState,widgetSettingsCss,selected_widget_id} = this.state

    cssState ={}
    cssState['borderRadius'] = e.target.value

    widgetSettingsCss[selected_widget_id] = {...widgetSettingsCss[selected_widget_id],...cssState,}

    this.setState({borderRadius:e.target.value,cssState,widgetSettingsCss})
  }

  setFontSize = (e) =>{
    let {cssState,widgetSettingsCss,selected_widget_id} = this.state
    cssState ={}
    cssState['fontSize'] = e.target.value

    widgetSettingsCss[selected_widget_id] = {...widgetSettingsCss[selected_widget_id],...cssState,}

    this.setState({fontSize:e.target.value,cssState,widgetSettingsCss})
  }

  setFontWeight = (e) => {
    let {cssState,selected_widget_id,widgetSettingsCss} = this.state
    cssState ={}
    cssState['fontWeight'] = e.target.value

    widgetSettingsCss[selected_widget_id] = {...widgetSettingsCss[selected_widget_id],...cssState,}

    this.setState({fontWeight:e.target.value,cssState,widgetSettingsCss})
  }

  setTextAlign = (e) => {
    let {cssState,selected_widget_id,widgetSettingsCss} = this.state
    cssState ={}
    cssState['textAlign'] = e.target.value

    widgetSettingsCss[selected_widget_id] = {...widgetSettingsCss[selected_widget_id],...cssState,}


    this.setState({textAlign:e.target.value,cssState,widgetSettingsCss})
  }

  setPadding = (e) => {
    let {cssState,selected_widget_id,widgetSettingsCss} = this.state
    cssState ={}
    cssState['padding'] = e.target.value

    widgetSettingsCss[selected_widget_id] = {...widgetSettingsCss[selected_widget_id],...cssState,}


    this.setState({textAlign:e.target.value,cssState,widgetSettingsCss})
  }

  setMaxWidth = (e) => {
    let {cssState,selected_widget_id,widgetSettingsCss} = this.state
    cssState ={}
    cssState['maxWidth'] = e.target.value

    widgetSettingsCss[selected_widget_id] = {...widgetSettingsCss[selected_widget_id],...cssState,}


    this.setState({textAlign:e.target.value,cssState,widgetSettingsCss})
  }

  setBoxShadow = (e) => {
    let {cssState,selected_widget_id,widgetSettingsCss} = this.state
    cssState ={}
    cssState['boxShadow'] = e.target.value

    widgetSettingsCss[selected_widget_id] = {...widgetSettingsCss[selected_widget_id],...cssState,}
    this.setState({boxShadow:e.target.value,cssState,widgetSettingsCss})
  }

  setBorder = (e) => {
    let {cssState,selected_widget_id,widgetSettingsCss} = this.state
    cssState ={}
    cssState['border'] = e.target.value

    widgetSettingsCss[selected_widget_id] = {...widgetSettingsCss[selected_widget_id],...cssState,}

    this.setState({border:e.target.value,cssState,widgetSettingsCss})
  }


  setWidgetConfig = (e) => {

    let {configState,widgetSettingsConfig,selected_widget_id} = this.state
    let name = e.target.name;
    let value = e.target.value

    if(e.target.name == "is_button" || e.target.name == "is_new_login" || e.target.name == "clickable_area"){
      configState[name] = e.target.checked
      widgetSettingsConfig[selected_widget_id][name]=e.target.checked
    }else{
      configState[name] = value
      widgetSettingsConfig[selected_widget_id][name]=value
    }

    this.setState({configState,widgetSettingsConfig})
  }

  setWidgetClass = (e) => {

    let {classState} = this.state
    let name = e.target.name;
    let value = e.target.value

    classState[name] = value
    this.setState({classState})
  }

  /*setPseudoClass = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    this.setState({[name]:value})
  }*/

  handleClose = () =>this.setState({show:false})

  handleChange = (e) =>{

    let {configState} = this.state
    let name = e.target.name
    let value = e.target.value

    if(name == 'widget_type')
      this.setState({selectBanner:"none",selectCourse:"none",selectQuickLinks:"none",selectForm:"none"})

    if(e.target.value == 1 || e.target.value ==7){
      this.setState({selectBanner:"block"})
    }
    if(e.target.value == 2){
      this.setState({selectCourse:"block"})
    }
    if(e.target.value == 5){
      this.setState({selectQuickLinks:"block"})
    }
    if(e.target.value == 4){
      this.setState({selectForm:"block"})
    }

    if(name == "rowHeading") configState['heading']= value
    if(name == "image") configState['image']= value
    if(name == "navigation_url") configState['navigation_url']= value
    if(name == "description") configState['description']= value
    
    this.setState({[name]:value})
  }

  onSetting = () =>{

     store.addNotification({
      title: 'Success',
      message: `${this.props.saveLanguageWiseData.widget_setting_saved}`,
      type: 'success',
      container: 'top-right',
      dismiss: {
        duration: 3000,
      }
    })

    this.setState({show:false})
  }

  addWidget = (list,widget,widgetrows) => {
    if(!Object.keys(list).length){
      if(widget.length){
        list = widget[widget.length-1]
      }else{
        list = widgetrows
      }
    }

    console.log(list)
    this.setState({widget_type:"",size:"", selected:"", course_type:"",box_type:"",selected_quick_links:"", selectQuickLinks:"none",selectCourse:"none" ,selectBanner:"none",selected_form:"",selectForm:"none"})
    this.setState({addClass:false,show:true,widgetData:list,WidgetColumnList:widget,selected_widget_id:''})
  }


  addNewWidget = async () => {
    let updatedArray ={}
    let val = this.state.widgetData
    let data =val?.data

    const {widgetSettingsCss,selected_form,widget_type,selected_widget_id,selectedPage,selected,selected_quick_links,classState,configState} = this.state

    let {settings,page_url} = this.state

    let widget_parent_id = widget_type || val.widget_master_id
    let position = val.position
    let size = this.state.size || val.size
    let row= val.row || 0
    let column = val.type == "row" ? 1 : widget_type ? parseInt(val.column) +1:val.column
    let title = this.state.widget_title

    console.log(row+"/"+column)

    if(widget_type && widget_type == 1){
      data ={}
      data = {"banner_ids":selected?.map( d => d.value).join(",")}
    }
    if(widget_type && widget_type == 2){
      data={}
      
      if(this.state.box_type == "Box_2"){
        data = {"type":"tags","tags":"Box_2"}
      }else{
        data = {"type": this.state.course_type =='paid'?"2":"1","tags":this.state.box_type}
      }
    }
    if(widget_type && widget_type == 5){
      data={}
      data = {"content_types":selected_quick_links?.map( d => d.value).join(",")}
    }
    if(widget_type && widget_type == 4){
      data={}
      data = {"form_id":selected_form.toString()}
    }

    if(selected_widget_id){
      page_url = selectedPage?.map(p => p.value)
      configState['placeholders'] =  !Array.isArray(configState['placeholders']) ? configState['placeholders']?.split(",") : configState['placeholders']
      configState['repeat'] = !Array.isArray(configState['repeat']) ? configState['repeat']?.split(",").map( rpt => ({"delay":rpt})) :configState['repeat']

      settings ={"settings": {"class":classState,"css":widgetSettingsCss[selected_widget_id],"config":configState}}
    }

    if(!selected_widget_id){
      let existColumns = this.state.WidgetColumnList.filter((e) => {
          return e.column >= column;
      });

      for(let i =0; i<existColumns.length; i++){
        updatedArray[existColumns[i].id] = [row,parseInt(existColumns[i].column) +1]
      }
    }
    
    await this.props.widgetSubmit(widget_parent_id,size,position,data,settings,row,column,selected_widget_id,title);
    
    if(Object.keys(updatedArray).length >0){
      await this.props.widgetReorder(updatedArray);
    }

    this.instituteWidgetList();
    this.setState({show:false})

  }

  addRow = async(val) =>{
    this.setState({addRow:true,rowData:val,selected_widget_id:'',rowHeading:"",description:"",image:"",navigation_url:""})
  }



  widgetDelete = async () =>{

    const {CompleteWidgetArray} = this.state

    let updatedArray ={}

    let widgetRows = this.state.widgetRows
    let WidgetRowOnDelete = widgetRows.filter(p => p.id == this.state.selected_widget_id)

    let row = WidgetRowOnDelete[0].row

    /**************************************************/
    let existRows = widgetRows.filter((e) => {
            return e.row > row;
    });
    for(let i =0; i<existRows.length; i++){

        let swapColumns = CompleteWidgetArray?.filter(w => w.type !='popup' && w.type !='row' && parseInt(w.row) === parseInt(existRows[i].row)).sort((a,b) => parseInt(a.column) - parseInt(b.column))
        
        console.log(swapColumns)
        if(swapColumns.length){
          for(let j =0; j<swapColumns.length; j++){
            updatedArray[swapColumns[j].id] = [parseInt(swapColumns[j].row) -1,swapColumns[j].column]
          }
        }
        updatedArray[existRows[i].id] = [parseInt(existRows[i].row) -1,existRows[i].column]
    }

    /*********************************************/


    /*********************************************/
    await this.props.widgetDelete(this.state.selected_widget_id)

    let WidgetsToDelete = widgetRows.filter((e) => {
            return e.row == row;
    });
    for(let i =0; i<WidgetsToDelete.length; i++){
      let columnsList = CompleteWidgetArray?.filter(w => w.type !='popup' && w.type !='row' && parseInt(w.row) === parseInt(WidgetsToDelete[i].row)).sort((a,b) => parseInt(a.column) - parseInt(b.column))
      if(columnsList.length){
          for(let j =0; j<columnsList.length; j++){
            await this.props.widgetDelete(columnsList[j].id)
            //updatedArray[swapColumns[j].id] = [parseInt(swapColumns[j].row) -1,swapColumns[j].column]
          }
      }
    }
    /**********************************************/

    if(Object.keys(updatedArray).length > 0)
      await this.props.widgetReorder(updatedArray);

    this.instituteWidgetList();
    this.setState({deleteWidget:false})
  }

  deleteWidgetComponent = async () => {

    const {CompleteWidgetArray} = this.state

    let updatedArray ={}

    let WidgetsOnDelete = CompleteWidgetArray.filter(p => p.id == this.state.selected_widget_id)

    let row = WidgetsOnDelete[0].row
    let column = WidgetsOnDelete[0].column

    /**************************************************/
    let existWidgets = WidgetsOnDelete.filter((e) => {
            return e.type != "row" && e.type!="popup" && e.column > column;
    });
    for(let i =0; i<existWidgets.length; i++){
        updatedArray[existWidgets[i].id] = [parseInt(existWidgets[i].row),parseInt(existWidgets[i].column)-1]
    }
    /*********************************************/

    /*********************************************/
    await this.props.widgetDelete(this.state.selected_widget_id)

    /**********************************************/

    if(Object.keys(updatedArray).length > 0)
      await this.props.widgetReorder(updatedArray);

    this.instituteWidgetList();
    this.setState({deleteWidget:false})
  }

  isDeleteWidget = (id,type) => {
    this.setState({deleteWidget:true,selected_widget_id:id,selected_type:type})
  }

  closeBox = () =>{
    this.setState({deleteWidget:false})
  }

  addNewRow = async() =>{

    let updatedArray = {}

    const {selected_widget_id,CompleteWidgetArray,classState,widgetSettingsCss,configState} = this.state

    let val = this.state.rowData

    let widgetRows = this.state.widgetRows

    let widget_parent_id = val.widget_master_id
    let size = val.size
    let data ={}
    let position = val.position
    let row = selected_widget_id ? val.row:parseInt(val.row)+1
    let title = val.title

    if(!selected_widget_id){
      let existRows = widgetRows.filter((e) => {
            return e.row >= row;
      });

      for(let i =0; i<existRows.length; i++){

        let swapColumns = CompleteWidgetArray?.filter(w => w.type !='popup' && w.type !='row' && parseInt(w.row) === parseInt(existRows[i].row)).sort((a,b) => parseInt(a.column) - parseInt(b.column))
        
        if(swapColumns.length){
          for(let j =0; j<swapColumns.length; j++){
            updatedArray[swapColumns[j].id] = [parseInt(swapColumns[j].row) +1,swapColumns[j].column]
          }
        }
        updatedArray[existRows[i].id] = [parseInt(existRows[i].row) +1,existRows[i].row]
      }
    }

    let settings ={"settings": {"css":widgetSettingsCss[selected_widget_id],"config":configState}}

    await this.props.widgetSubmit(widget_parent_id,size,position,data,settings,row,1,selected_widget_id,title);

    if(Object.keys(updatedArray).length > 0)
      await this.props.widgetReorder(updatedArray);

    this.instituteWidgetList();
    this.setState({addRow:false})
  }

  rowClose = () =>{
    this.setState({addRow:false})
  }

  toggle() {
    this.setState({addClass: !this.state.addClass});
  }

  getSettingInfo = (list) =>{

    console.log(list)

    let setting = list.settings
    let id = list.id
    //return false
    let css = setting.settings?.css
    let config = setting.settings?.config
    let pseudoClass = setting.settings?.class

    let {cssState} = this.state
    let {classState} = this.state
    let {configState} = this.state

    if(css){
      cssState ={}
      for (const property in css) {
        cssState[property] = css[property]
      }
    }
    if(config){
      configState={}
      for (const property in config) {
        configState[property] = config[property]
        if(property == 'placeholders')
          configState[property] = config['placeholders'].join(',')
        if(property == 'repeat')
          configState[property] = config['repeat'].map(r => r.delay).join(',')
      }
    }
    if(pseudoClass){
      classState ={}
      for (const property in pseudoClass) {
        classState[property] = pseudoClass[property]
      }
    }

    this.setState({addClass:true,selected_type:list.type,size:list.size,widget_title:list.title,selected_widget_id:id,widgetData:list,rowData:list,cssState,classState,configState,show:false})
  }

  changeBannerField = (e) =>{

    let {bannerStruct} = this.state

    let name = e.target.name
    let value = e.target.value

    if(name =="banner_image" && e.target.files[0].type.split('/')[0]) {
      bannerStruct['banner_image'] = e.target.files[0]
      bannerStruct['banner_image_url'] =  URL.createObjectURL(e.target.files[0])
    }else{
      bannerStruct[name] = value
    }

    this.setState({bannerStruct})
  }

  changeFormField = (e) =>{

    let {formStruct} = this.state

    let name = e.target.name
    let value = e.target.value

    formStruct[name] = value

    this.setState({formStruct})
  }

  addForm = () =>{
    this.setState({addForm:true,show:false})
  }

  addNewForm = async() =>{
    const {formStruct} = this.state
    const response = await this.props.formCreate(formStruct['form_title'],formStruct['form_description'],formStruct['success_message'],formStruct['form_type'])
    
    if(response != undefined){
      this.setState({addForm:false,show:true})
    }

    this.formList()

  }

  on_refer_click = async (listid) =>{
    let widget_response = await this.props.getWidgetDataById(listid);
    if(widget_response != undefined){
      this.setState({widget_form_data:widget_response.data?.questions})
    }

    this.setState({open_refer :true,onsave:false,selected_list_id:listid,fields:{}})

    /*let newdashboardnavigation = document.getElementsByClassName("new-dashboard-navigation")
    if(newdashboardnavigation) newdashboardnavigation[0].style.opacity =0.1

    let appheader = document.getElementsByClassName("app-header")
    if(appheader) appheader[0].style.opacity= 0.1*/

  }

  close_refer = () => {
    this.setState({open_refer : false})
  }

  changeWidgetTitle = (e) =>{
    this.setState({widget_title:e.target.value})
  }

  handleInputChange = (event,id,o_index) =>{

    const {name} = event.target;
    let {fields,isEnabled} = this.state
    let errors = this.state.errors;
    
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    fields[name] = value

    let { widget_form_data } = this.state

    switch (name) {
      case 'name': 
        errors[name] = 
          value.length ==0
            ? 'name must not be blank!!'
            : '';
        break;
      case 'email': 
        errors[name] = 
          validEmailRegex.test(value)
            ? ''
            : 'Email is not valid!';
        break;
      case 'mobile_no.': 
        errors[name] = 
          !validMobileRegex.test(value) ||  value.length > 10 || value.length <10
            ? 'Mobile no. is not valid!'
            : '';
        break;
      default:
        break;
    }


    let widget_form_index = widget_form_data.findIndex( p => parseInt(p.id) === parseInt(id))


    if(target.type === 'checkbox' )
      widget_form_data[widget_form_index].options[o_index].is_select = event.target.checked;
    else
      widget_form_data[widget_form_index].user_solution = value

    if(widget_form_data.length == Object.keys(errors).length){
      for (const [key,errs] of Object.entries(errors)) {
        console.log("errs"+errs)
        if(errs){
          isEnabled = false;
          break;
        }else{
          isEnabled = true;
        }
      }
    }

    console.log(errors)
    console.log(isEnabled)
    this.setState({isEnabled,widget_form_data,errors,fields})
  }

  postAnswers = async () => {

    const {widget_form_data} = this.state

    let {questions} = this.state

    console.log(widget_form_data)
    questions =[]

    for(let i=0 ; i < widget_form_data.length; i++){
      let optionVal = {}

      if(widget_form_data[i].options.length >0){
        let selected_option_id = widget_form_data[i].options.filter((option) => option.is_select == true)
        optionVal ={"question_id": widget_form_data[i].id,"selected_option_id":selected_option_id.map(l => l.id),"user_solution":""}
      }else{
        optionVal ={"question_id": widget_form_data[i].id,"selected_option_id":[],"user_solution":widget_form_data[i].user_solution}
      }
      
      questions.push(optionVal)
    }

    console.log(questions)
    this.setState({questions})
    const response = await this.props.widgetQuestionSubmit(questions)

    if(response != undefined){
      console.log(response.data.status)

      if(parseInt(response.data.status) === parseInt(1)){
        
        this.setState({onsave:true,open_refer:false})
        document.getElementsByClassName("new-dashboard-navigation")[0].style.opacity =0.1 
        document.getElementsByClassName("app-header")[0].style.opacity= 0.1
      }else{
        this.setState({onsave:false})
      }
    }
  }

  closeAddBanner = () =>{
    this.setState({addBanner:false})
  }

  closeAddForm = () =>{
    this.setState({addForm:false})
  }

  getUrl = async (banner_type,url) =>{
    if(banner_type == "partner_site"){
      const response = await this.props.getwpUserToken();
      const username = localStorage.getItem("username")

      window.open(`${url}/1.php?token=${response.data?.token}&username=${username}&slug=''`, "_self");
    }else{
      window.open(`${url}`,"_blank");
    }
  }

  setLink = (lecture) => {
    if(lecture.liveClass_server == 2){
       return(
        <>
          {lecture.action_label == "Scheduled" ? <span>{lecture.action_label}</span> :<a href="#" onClick={() => this.bbbJoinLiveClass(lecture.etc,lecture.live_class_Session)}>{lecture.action_label}</a>}
        </>
      )
    }

    else{
       return(
        <>
          {lecture.action_label == "Scheduled" ? <span>{lecture.action_label}</span> :<a index={lecture.id} href={lecture.url} target="_blank">{lecture.action_label}</a>}
        </>
      )
    }
  }

  addBanner = () =>{
    this.setState({addBanner:true,show:false})
  }

  render() {
    const {
      exerciseAttempt,
      currentQuestion,
      currentSection,
      loading,
      widgetMasterList,
      bannerList,
      classState,
      configState,
      selected_type,
      cssState,
      widgetRows,widgetList,widgetdata,widgetIds,widgetSettings,widgetSettingsCss,widgetSettingsConfig,username,fields,errors
    } = this.state;

    if(loading) {
      return (
        <div className="assessment-div">
          <div className="admin-div-loader">
            <div className="admin-loader"></div>
          </div>
        </div>
      )
    }

    return (
      <div className="widget-div widget-dashboard">
        <Helmet>
          <title>Assessment Preview - {this.state.title}</title>
        </Helmet>
        <ReactNotifications/>

        <Popup open={this.state.addRow} closeOnDocumentClick onClose={this.rowClose} className="row-type">
          <div className="add-row">
            <span className="close" onClick={this.rowClose}>&#x2715;</span>
            <div className="sub-div-01">
              <span>Add New Row</span>
              <hr/>
              <div className="row">
                <div className="col-25">
                  <label for="fname">Add Title</label>
                </div>
                <div className="col-75">
                  <input type="text" value={this.state.rowHeading} onChange={this.handleChange} name="rowHeading"/>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">Image Url</label>
                </div>
                <div className="col-75">
                  <input type="text" value={this.state.image} onChange={this.handleChange} name="image"/>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">Navigation Url</label>
                </div>
                <div className="col-75">
                  <input type="text" value={this.state.navigation_url} onChange={this.handleChange} name="navigation_url"/>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">Description</label>
                </div>
                <div className="col-75">
                  <input type="text" value={this.state.description} onChange={this.handleChange} name="description"/>
                </div>
              </div>
            </div>
            <div id="type-button" className="dialogactions">
              <button className="widget-button" onClick={this.addNewRow}>Proceed</button>
            </div>
          </div>
        </Popup>

        <Popup open={this.state.onsave} closeOnDocumentClick onClose={this.onsave} className="referral-end">
          <div class="referral-thanksmsg">
            <span className="close" onClick={this.onsave}>&#x2715;</span>
            <div className="thanksmsg"><i><img src={tickicon} /></i>Thank you for your referral!!</div>
            <div className="addmore-msg">Do you want to refer more <a onClick={() => this.on_refer_click(this.state.selected_list_id)}>click here</a></div>
          </div>
        </Popup>

        <Popup open={this.state.open_refer} closeOnDocumentClick onClose={this.close_refer} className="referralDialog">
          <div className="design-block referralDialogWrapper">
            <span className="close" onClick={this.close_refer}>&#x2715;</span>
            <div className="shape1"></div>
            <div className="shape2"></div>
            <div className="leftsection">
            <div className="headingtitle">Referral</div>
            <ol>
              {this.state.widgetdata[this.state.selected_list_id]?.questions?.length && this.state.widgetdata[this.state.selected_list_id].questions.map((item,id) =>
                  <li key={id}>
                    <div className="question-title">{item.statement}</div>
                    <div className="labelblock">
                    {item.options.length ? item.options.map((option,index) => 
                       <label> <input
                        name={option.id}
                        type="checkbox"
                        onChange={(e) =>this.handleInputChange(e,item.id,index)} /><span>{option.statement}</span></label>
                    ) : <div className="labelinputblock"><input
                        name={item.statement.toLowerCase().split(" ").join("_")}
                        type="text"
                        value={fields[item.statement.toLowerCase().split(" ").join("_")]}
                        onChange={(e) => this.handleInputChange(e,item.id,"null")} /> {errors[item.statement.toLowerCase().split(" ").join("_")]?.length > 0 && 
                        <span className='error'>{errors[item.statement.toLowerCase().split(" ").join("_")]}</span>}</div>}
                       </div> 
                </li>
              )}  

                 
            </ol>
            <div className="question-action">
              <button  disabled={this.state.isEnabled == false}  onClick={()=>this.postAnswers()}>Save</button>
            </div>
            </div>
            <div className="rightsection"></div>
          </div>
        </Popup>

        <Popup open={this.state.deleteWidget} closeOnDocumentClick onClose={this.closeBox}
          className="popup-content6">
          <div className="delete-up">
            <img src={Trash} alt="delete"/>
            {selected_type == "row" ? "All associated widgets will delete on deleting rows ,do you want to continue?" :"Do you want to delete selected widget?"}
          </div>
          <div className="delete-down">
            <button onClick={this.closeBox}>
              Cancel
            </button>
            {selected_type == "row" ? <button onClick={this.widgetDelete}>
              Delete
            </button> :<button onClick={this.deleteWidgetComponent}>
              Delete
            </button>}
          </div>
        </Popup>
        <Popup open={this.state.addBanner} closeOnDocumentClick onClose={this.closeAddBanner} className="row-type">
          <div className="add-row">
            <span className="close" onClick={this.closeAddBanner}>&#x2715;</span>
            <div className="sub-div-01">
              <span>Add Banner</span>
              <hr/>
              <div className="row">
                <div className="col-25">
                  <label for="fname">Title</label>
                </div>
                <div className="col-75">
                  <input type="text" value={this.state.bannerStruct['banner_title']} onChange={this.changeBannerField} name="banner_title"/>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">Description</label>
                </div>
                <div className="col-75">
                  <input type="text" value={this.state.bannerStruct['banner_description']} onChange={this.changeBannerField} name="banner_description"/>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">Start date</label>
                </div>
                <div className="col-75">
                  <input type="datetime-local" value={this.state.bannerStruct['start_date_time']} onChange={this.changeBannerField} name="start_date_time"/>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">End date</label>
                </div>
                <div className="col-75">
                  <input type="datetime-local" value={this.state.bannerStruct['end_date_time']} onChange={this.changeBannerField} name="end_date_time"/>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">Banner type</label>
                </div>
                <div className="col-75">
                  <select value={this.state.bannerStruct['banner_type']} onChange={this.changeBannerField} name="banner_type">
                    <option value="Certification_Academy">Certification Academy</option>
                    <option value="Live_Projects">Live Projects</option>
                    <option value="Stationary_Shop">Stationary Shop</option>
                    <option value="Events">Events</option>
                    <option value="Generic">Generic</option>
                    <option value="banner">banner</option>
                    <option value="heading">heading</option>
                    <option value="partner_site">Partner Site</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">level</label>
                </div>
                <div className="col-75">
                  <input type="text" value={this.state.bannerStruct['banner_level']} onChange={this.changeBannerField} name="banner_level"/>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">Url</label>
                </div>
                <div className="col-75">
                  <input type="text" value={this.state.bannerStruct['banner_url']} onChange={this.changeBannerField} name="banner_url"/>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">Image</label>
                </div>
                <div className="col-75">
                  <input type="file" value="" onChange={this.changeBannerField} name="banner_image"/>
                </div>
              </div>
            </div>
            <div id="type-button" className="dialogactions">
              <button className="widget-button" onClick={this.addNewBanner}>Add</button>
            </div>
          </div>
        </Popup>

        <Popup open={this.state.addForm} closeOnDocumentClick onClose={this.closeAddForm} className="row-type">
          <div className="add-row">
            <span className="close" onClick={this.closeAddForm}>&#x2715;</span>
            <div className="sub-div-01">
              <span>Add Form</span>
              <hr/>
              <div className="row">
                <div className="col-25">
                  <label for="fname">Title</label>
                </div>
                <div className="col-75">
                  <input type="text" value={this.state.formStruct['form_title']} onChange={this.changeFormField} name="form_title"/>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">Description</label>
                </div>
                <div className="col-75">
                  <input type="text" value={this.state.formStruct['form_description']} onChange={this.changeFormField} name="form_description"/>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">Type</label>
                </div>
                <div className="col-75">
                  <input type="text" value={this.state.formStruct['form_type']} onChange={this.changeFormField} name="form_type"/>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">Message</label>
                </div>
                <div className="col-75">
                  <input type="text" value={this.state.formStruct['success_message']} onChange={this.changeFormField} name="success_message"/>
                </div>
              </div>
            </div>
            <div id="type-button" className="dialogactions">
              <button className="widget-button" onClick={this.addNewForm}>Add</button>
            </div>
          </div>
        </Popup>


        <Popup open={this.state.show} closeOnDocumentClick onClose={this.handleClose} className="banner-type">
          <div className="referral-thanksmsg">
            <span className="close" onClick={this.handleClose}>&#x2715;</span>
            <div className="sub-div-01">
              <span>Add Widget</span>
              <hr/>
              <div className="row">
                <div className="col-25">
                  <label for="fname">Select Type of Widget</label>
                </div>
                <div className="col-75">
                  <select value={this.state.widget_type} onChange={this.handleChange} name="widget_type">
                    <option value="">--select widget type--</option>
                    {widgetMasterList?.length && widgetMasterList.map( wgts => 
                      <option value={wgts.id}>{wgts.type}</option>
                    )}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">Widget Title</label>
                </div>
                <div className="col-75">
                  <input type="text" value={this.state.widget_title} onChange={this.changeWidgetTitle}/>
                </div>
              </div>
              <div className="row">
                <div className="col-25">
                  <label for="fname">Select Widget Size</label>
                </div>
                <div className="col-75">
                  <select value={this.state.size} onChange={this.handleChange} name="size">
                    <option value="">--select widget size--</option>
                    <option value="1x1">1x1</option>
                    <option value="1x2">1x2</option>
                    <option value="1x3">1x3</option>
                    <option value="2x1">2x1</option>
                    <option value="2x2">2x2</option>
                    <option value="2x3">2x3</option>
                  </select>
                </div>
              </div>
              <div className="row" style={{display:this.state.selectBanner}}>
                <div className="col-25">
                  <label for="fname">Select Banners</label>
                </div>
                <div className="col-75">
                  <div className="col-75-partition ">
                    <div className='addBannerClassWrapper width'>
                    <MultiSelect
                      options={bannerList}
                      value={this.state.selected}
                      onChange={this.setSelected}
                      labelledBy="Select Programs"
                    />
                    </div>
                    <div> OR <button className="widget-button" onClick={this.addBanner}>Add Banner</button></div>
                  </div>
                </div>
              </div>
              <div className="row" style={{display:this.state.selectCourse}}>
                <div className="col-25">
                  <label for="fname">Select Course Type</label>
                </div>
                <div className="col-75">
                  <select value={this.state.course_type} onChange={this.handleChange} name="course_type">
                    <option value="">--select--</option>
                    <option value="paid">Paid</option>
                    <option value="free">Free</option>
                  </select>
                </div>
              </div>
              <div className="row" style={{display:this.state.course_type == "free"?"block":"none"}}>
                <div className="col-25">
                  <label for="fname">Select Box Type</label>
                </div>
                <div className="col-75">
                  <select value={this.state.box_type} onChange={this.handleChange} name="box_type">
                    <option value="">--select--</option>
                    <option value="Box_1">box_1</option>
                    <option value="Box_2">box_2</option>
                  </select>
                </div>
              </div>
              <div className="row" style={{display:this.state.selectQuickLinks}}>
                <div className="col-25">
                  <label for="fname">Quick Links</label>
                </div>
                <div className="col-75">
                  <MultiSelect
                    options={quickLinkList}
                    value={this.state.selected_quick_links}
                    onChange={this.setQuickLinks}
                    labelledBy="Select quick links"
                  />
                </div>
              </div>
              <div className="row" style={{display:this.state.selectForm}}>
                <div className="col-25">
                  <label for="fname">Forms</label>
                </div>
                <div className="col-75">
                  
                  <div className="col-75-partition">
                    <select value={this.state.selected_form} onChange={this.handleChange} name="selected_form">
                      <option value="">--select--</option>

                      {this.state.formList?.length > 0 && this.state.formList?.map( form => 

                          <option value={form.id}>{form.title}</option>
                      )}
                    </select>
                    <div> OR <button className="widget-button" onClick={this.addForm}>Add Form</button></div>
                  </div>
                </div>
              </div>
              <div id="type-button" className="dialogactions">
                <button className="widget-button" onClick={this.addNewWidget}>Proceed</button>
              </div>
            </div>
          </div>
        </Popup>

        <div  className={this.state.addClass ? 'widget-div-first-child widgetsidebaropen': "widget-div-first-child"}>
          {/*<div className="settings-customizer" onClick={this.toggle.bind(this)}><img src={settingicon}/></div>*/}
          <div className="widget-setting-button">
              {selected_type == "row" ? <Button variant="primary" className="widget-button" onClick={this.addNewRow}>Save</Button> : <Button variant="primary" className="widget-button" onClick={this.addNewWidget}>Save</Button>}
              <Button variant="primary" className="widget-button" onClick={this.toggle.bind(this)}>Close</Button>
            </div>
          <div className="widget-css-setting">
            
            <div className="widget-heading"><h1>Widget Styling</h1></div>
            <div className="setting">
              <div className="first-child">BackGround Color</div>
              <div className="second-child"><ColorPicker color={this.state.cssState['backgroundColor']} setColor={this.setBgColor} name="backgroundColor"/></div>
            </div>
            <div className="setting">
              <div className="first-child">Color</div>
              <div className="second-child"><ColorPicker color={this.state.cssState['color']} setColor={this.setColor} name="color"/></div>
            </div>
            <div className="setting">
              <div className="first-child">BorderRadius(px)</div>
              <div className="second-child"><input type="text" value={this.state.cssState['borderRadius']} onChange={this.setBorderRadius} name="borderRadius"/></div>
            </div>
            <div className="setting">
              <div className="first-child">FontSize(px)</div>
              <div className="second-child"><input type="text" value={this.state.cssState['fontSize']} onChange={this.setFontSize} name="fontSize"/></div>
            </div>
            <div className="setting">
              <div className="first-child">FontWeight</div>
              <div className="second-child">
                <select value={this.state.cssState['fontWeight']} onChange={this.setFontWeight} name="fontWeight">
                  <option value="">--select--</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                  <option value="300">300</option>
                  <option value="400">400</option>
                  <option value="500">500</option>
                  <option value="600">600</option>
                </select>
              </div>
            </div>
            <div className="setting">
              <div className="first-child">TextAlign</div>
              <div className="second-child">
                <select value={this.state.cssState['textAlign']} onChange={this.setTextAlign} name="textAlign">
                  <option value="">--select--</option>
                  <option value="left">left</option>
                  <option value="right">Right</option>
                  <option value="end">End</option>
                  <option value="start">Start</option>
                </select>
              </div>
            </div>
            <div className="setting">
              <div className="first-child">Box Shadow(1px 3px 1px #9E9E9E)</div>
              <div className="second-child">
                <div className="second-child"><input type="text" value={this.state.cssState['boxShadow']} onChange={this.setBoxShadow} name="boxShadow"/></div>
              </div>
            </div>
            <div className="setting">
              <div className="first-child">Border(1px solid #ddd)</div>
              <div className="second-child">
                <div className="second-child"><input type="text" value={this.state.cssState['border']} onChange={this.setBorder} name="border"/></div>
              </div>
            </div>
            <div className="setting">
              <div className="first-child">Padding(1px)</div>
              <div className="second-child">
                <div className="second-child"><input type="text" value={this.state.cssState['padding']} onChange={this.setPadding} name="padding"/></div>
              </div>
            </div>
            <div className="setting">
              <div className="first-child">Max Width</div>
              <div className="second-child">
                <div className="second-child"><input type="text" value={this.state.cssState['maxWidth']} onChange={this.setMaxWidth} name="maxWidth"/></div>
              </div>
            </div>
          </div>
          <div className="widget-css-setting" style={{display:this.state.selected_type == "row"?"none":""}}>
            <div className="widget-heading">
              Widget Pseudo Class    
            </div>
            <div className="setting">
              <div className="first-child">Image</div>
              <div className="second-child">
                <select value={this.state.classState['image']} onChange={this.setWidgetClass} name="image">
                  <option value="">--select--</option>
                  <option value="left">left</option>
                  <option value="right">right</option>
                  <option value="background">background</option>
                </select>
              </div>
            </div>
            <div className="setting">
              <div className="first-child">Type</div>
              <div className="second-child">
                <select value={this.state.classState['type']} onChange={this.setWidgetClass} name="type">
                  <option value="">--select--</option>
                  <option value="card">Card</option>
                  <option value="banner">Banner</option>
                </select>
              </div>
            </div>
            <div className="setting">
              <div className="first-child">background-color</div>
              <div className="second-child">
                <select value={this.state.classState['background-color']} onChange={this.setWidgetClass} name="background-color">
                  <option value="">--select--</option>
                  <option value="random">random</option>
                  <option value="systematic">systematic</option>
                </select>
              </div>
            </div>
          </div>
          <div className="widget-css-setting" style={{display:this.state.selected_type == "row"?"block":"none"}}>
            <div className="widget-heading"><h1>Widget Config</h1></div>
            <div className="setting">
              <div className="first-child">Row Heading</div>
              <div className="second-child">
                <input type="text" value={this.state.configState['heading']} onChange={this.setWidgetConfig} name="heading"/>
              </div>
            </div>
            <div className="setting">
              <div className="first-child">Image Url</div>
              <div className="second-child">
                <input type="text" value={this.state.configState['image']} onChange={this.setWidgetConfig} name="image"/>
              </div>
            </div>
            <div className="setting">
              <div className="first-child">Navigation Url</div>
              <div className="second-child">
                <input type="text" value={this.state.configState['navigation_url']} onChange={this.setWidgetConfig} name="navigation_url"/>
              </div>
            </div>
            <div className="setting">
              <div className="first-child">Description</div>
              <div className="second-child">
                <input type="text" value={this.state.configState['description']} onChange={this.setWidgetConfig} name="description"/>
              </div>
            </div>
          </div>
          <div className="widget-css-setting" style={{display:this.state.selected_type == "row"?"none":""}}>
            <div className="widget-heading"><h1>Widget Config</h1></div>
            <div className="setting">
              <div className="first-child">Access Type</div>
              <div className="second-child">
                <select value={this.state.configState['access_type']} onChange={this.setWidgetConfig} name="access_type">
                  <option value="">--select--</option>
                  <option value="global">global</option>
                  <option value="local">local</option>
                </select>
              </div>
            </div>
            <div className="setting">
              <div className="first-child">page url</div>
              <div className="second-child second-child-page-url">
                  <MultiSelect
                    options={pageUrlLists}
                    value={this.state.selectedPage}
                    onChange={this.setSelectedPage}
                    labelledBy="Select Pages"
                  />
              </div>
            </div>
            <div className="setting">
              <div className="first-child">is new_login</div>
              <div className="second-child">
                <input type="checkbox" checked={this.state.configState['is_new_login']} onChange={this.setWidgetConfig} name="is_new_login"/>is new_login
              </div>
            </div>
            <div className="setting">
              <div className="first-child">clickable area</div>
              <div className="second-child">
                <input type="checkbox" checked={this.state.configState['clickable_area']} onChange={this.setWidgetConfig} name="clickable_area"/>clickable area
              </div>
            </div>
            <div className="setting">
              <div className="first-child">Is button</div>
              <div className="second-child">
                <input type="checkbox" checked={this.state.configState['is_button']} onChange={this.setWidgetConfig} name="is_button"/>is button
              </div>
            </div>
            <div className="setting">
              <div className="first-child">display in_seconds</div>
              <div className="second-child">
                <input type="number" value={this.state.configState['display_in_seconds']} onChange={this.setWidgetConfig} name="display_in_seconds"/>
              </div>
            </div>
            <div className="setting">
              <div className="first-child">repeat in_seconds</div>
              <div className="second-child">
                <input type="text" value={this.state.configState['repeat']} onChange={this.setWidgetConfig} name="repeat"/>
              </div>
            </div>
            <div className="setting">
              <div className="first-child">Button Text</div>
              <div className="second-child">
                <input type="text" value={this.state.configState['anchor_text']} onChange={this.setWidgetConfig} name="anchor_text"/>
              </div>
            </div>
            <div className="setting">
              <div className="first-child">placeholders</div>
              <div className="second-child">
                <input type="text" name="font-size" value={this.state.configState['placeholders']} onChange={this.setWidgetConfig} name="placeholders"/>
              </div>
            </div>
            <div className="setting">
              <div className="first-child">Widget Size</div>
              <div className="second-child">
                <select value={this.state.size} onChange={this.handleChange} name="size">
                  <option value="">--select widget size--</option>
                  <option value="1x1">1x1</option>
                  <option value="1x2">1x2</option>
                  <option value="1x3">1x3</option>
                  <option value="2x1">2x1</option>
                  <option value="2x2">2x2</option>
                  <option value="2x3">2x3</option>
                </select>
              </div>
            </div>
            <div className="setting">
              <div className="first-child">Widget Title</div>
              <div className="second-child">
                <input type="text" value={this.state.widget_title} onChange={this.changeWidgetTitle}/>
              </div>
            </div>
          </div>
        </div>
        <div className="widget-div-second-child">
          {/*this.state.is_preview && <WidgetPreview  widgetDelete={this.widgetDelete} cssState ={this.state.cssState} getSettingInfo ={this.getSettingInfo} addRow={this.addRow} addWidget={this.addWidget}/>*/}
          {/*<Widget finalSpaceCharacters={finalSpaceCharacters}/>*/}

          {/*Code start from here*/}
            <div className="IADashboardWrapper1">
              <div className="IADashboardContainer1">
                {this.state.widgetList.length > 0 && this.state.widgetList.map((widget,key) => 
                  <>{key && <section className={this.state.selected_widget_id == widgetRows[key-1]?.id ? "rowWrapper rowWrapperBorder":"rowWrapper"} style={this.state.selected_widget_id == widgetRows[key-1]?.id ? widgetSettingsCss[this.state.selected_widget_id] : widgetRows[key-1]?.settings.settings?.css}>
                    <div className="rowWidget">
                    {widgetRows[key-1]?.settings?.settings?.config.heading ? <div className="courses-widget widget className-widget-heading"><div className="userinfo-widget"><div>{widgetRows[key-1]?.settings?.settings?.config.image && <img src={widgetRows[key-1]?.settings?.settings?.config.image} alt="heading"/>}</div><div><h3>{widgetRows[key-1]?.settings?.settings?.config.heading}</h3><p>{widgetRows[key-1]?.settings?.settings?.config.description}</p></div></div></div> :""} 
                        <div className="overlay-actions">
                          <a href="#" onClick={() => this.addWidget({},widget,widgetRows[key-1])} title="Add Widget"><img src={addwidget} title="Add Widget"/></a>
                          <a href="#" onClick={() =>this.addRow(widgetRows?.length && widgetRows[key-1],widgetRows)} title="Add Row"><img src={addicon}  title="Add Row"/></a>
                          <a href="#" onClick={() => this.getSettingInfo(widgetRows[key-1])} title="Edit Widget"><img src={editicon} alt="Edit widget"  /></a>
                          <a href="#" onClick={() => this.isDeleteWidget(widgetRows[key-1].id,widgetRows[key-1].type)} title="Delete Widget"><img src={Delete} alt="delete widget" /></a>
                        </div>  
                    </div>    
                  

                  <div className={"rowInner"}>
                    {widget.length ? widget.map((list,index) =>
                      <div className={(this.state.selected_widget_id == list.id ?"rowInnerBorder":"")+" column-widget column"+(list.size)} style={widgetSettingsCss[list.id]}>
                        
                        <div className={list.type === "courses" ? list.data.type == 2 ? `paid-course courses-widget widget ${widgetSettings[list.id]}` :list.data.type == "tags" && list.data.tags == "Box_2" ? `tag-course courses-widget widget ${widgetSettings[list.id]}`:`free-course courses-widget widget ${widgetSettings[list.id]}` : `courses-widget widget ${widgetSettings[list.id]}`}>
                        
                          {list.type === "courses" && <><section>
                        <div className="overlay-actions">
                            <a href="#" onClick={() => this.addWidget(list,widget,widgetRows[key-1])} title="Add Widget"><img src={addwidget}/></a>
                            <a href="#" onClick={() =>this.addRow(widgetRows?.length && widgetRows[key-1])} title="Add Row"><img src={addicon}/></a>
                            <a href="#" onClick={() => this.getSettingInfo(list)} title="Edit Widget"><img src={editicon} alt="Edit widget" /></a>
                            <a href="#" onClick={() => this.isDeleteWidget(list.id,list.type)} title="Delete Widget"><img src={Delete} alt="delete widget" /></a>
                        </div>  
                        <div className="heading-title">{list.title && list.title}</div>
                        {list.data.type == 2 ? widgetdata[12]?.length > 1 ? <Slider ref={c => (this.slider = c)} {...this.state.slidersettings}> {widgetdata[12].map(item =>
                            <div onClick={() => this.switchCourse(item?.batch_id)} className="paid-course-item"> 
                              <div className="course-thumb"><img src={PaidCourse} alt="subscribeicon"/></div>
                              <div className="arrow"><a href="#" onClick={() => this.switchCourse(item?.batch_id)}><img src={arrowicon} /></a></div>
                              <div className="course-title"><a href="/learner">{item.label}</a></div>
                              <div className="course-description">{item.description}</div>
                            </div>
                          )}</Slider> : <>{widgetdata[12].map(item =>
                            <div onClick={() => this.switchCourse(item?.batch_id)} className="paid-course-item paid-course-single-item"> 
                              <div className="course-thumb"><img src={PaidCourse} alt="subscribeicon"/></div>
                              <div className="arrow"><a href="#" onClick={() => this.switchCourse(item?.batch_id)}><img src={arrowicon} /></a></div>
                              <div className="course-title"><a href="/learner">{item.label}</a></div>
                              <div className="course-description">{item.description}</div>
                            </div>
                          )}</> : <>{list.data.type == "tags" && list.data.tags == "Box_2" ? <ol> <li>
                          <a href="https://game.imaginxp.live/" target="_blank">
                            <div className="arrow"><img src={arrowicon} /></div>
                            <div className="content">
                              <h3>"Scavenger Hunt"</h3>
                              <p>Gaming Masters</p>
                              <p>are you ready for the Quest?</p>
                            </div>
                          </a>
                        </li>{widgetdata[list.id]?.open_subjects.length && widgetdata[list.id]?.open_subjects.map(p =>
                            <li> 
                              <a href={"/learner/study-material/"+p.id}>
                                <div className="course-thumb"><img src={common.addFileUrl(p?.image_url)} alt="images" height="100px"/></div>
                                <div className="course-title">{p.label}</div>
                              </a>
                            </li>
                        )}</ol>:<><ol>{widgetdata[list.id]?.open_subjects.length && widgetdata[list.id]?.open_subjects.map(p =>
                            <li>
                              <a href={"/learner/study-material/"+p.id}> 
                                <div className="course-thumb"><img src={common.addFileUrl(p?.image_url)} alt="images" height="100px"/></div>
                                <div className="course-title">{p.label}</div>
                              </a>
                            </li>
                        )}</ol></>}</>
                      }
                      </section></>}

                      {list.type === "banners" && 
                        <><section>
                          <div className="overlay-actions">
                                <a href="#" onClick={() => this.addWidget(list,widget,widgetRows[key-1])} title="Add Widget"><img src={addwidget}/></a>
                                <a href="#" onClick={() =>this.addRow(widgetRows?.length && widgetRows[key-1])} title="Add Row"><img src={addicon}/></a>
                                <a href="#" onClick={() => this.getSettingInfo(list)} title="Edit Widget"><img src={editicon} alt="Edit widget" /></a>
                                <a href="#" onClick={() => this.isDeleteWidget(list.id,list.type)} title="Delete Widget"><img src={Delete} alt="delete widget" /></a>
                            </div> 
                            <div className="heading-title">{list.title && list.title}</div>
                          {widgetdata[list.id]?.length ==1 ? widgetdata[list.id]?.length && widgetdata[list.id].map(item =>
                            <>{widgetSettingsConfig[list.id] && 'clickable_area' in widgetSettingsConfig[list.id] && widgetSettingsConfig[list.id]['clickable_area'] ? <a href={item.url} target="_blank"><div className="userinfo-widget">
                              {item.image ? <div><img src={item?.image} /></div> :""}

                              <div Style={!item.content?"display:none":""}>
                                <div>{widgetSettingsConfig[list.id]?.placeholders?.includes('username') ? item.title.indexOf('{username}')!= -1 ?`Hi ${username}`:item.title : item.title}</div>
                                <p>{item.content}</p> 
                                {item.url ? <div className="explorebtn">{widgetSettingsConfig[list.id]?.is_button ? <button onClick={() => this.getUrl(item.banner_type,item.url)}>{widgetSettingsConfig[list.id].anchor_text ? widgetSettingsConfig[list.id].anchor_text :"Explore"}</button>:""}</div> :""}
                              </div> 
                            </div></a> :<div className="userinfo-widget">
                              {item.image ? <div><img src={item?.image} /></div> :""}
                              <div Style={!item.content?"display:none":""}>
                                <div>{widgetSettingsConfig[list.id]?.placeholders?.includes('username') ? item.title.indexOf('{username}')!= -1 ?`Hi ${username}`:item.title  : item.title}</div>
                                <p>{item.content}</p> 
                                {item.url ? <div className="explorebtn">{widgetSettingsConfig[list.id]?.is_button ? <button onClick={() => this.getUrl(item.banner_type,item.url)}>{widgetSettingsConfig[list.id].anchor_text ? widgetSettingsConfig[list.id].anchor_text :"Explore"}</button>:""}</div> :""}
                              </div>
                            </div>}</> ):<Carousel showArrows={false} showThumbs={false} emulateTouch={true} useKeyboardArrows={true}>
                            {widgetdata[list.id]?.length && widgetdata[list.id].map(item =>
                              <div className="userinfo-widget">
                                {item.image ? <div><img src={item?.image} /></div> :""}
                                <div>
                                  <div>{widgetSettingsConfig[list.id]?.placeholders?.includes('username') ? item.title.indexOf('{username}')!= -1 ?`Hi ${username}`:item.title  : item.title}</div>
                                  <p>{item.content}</p> 
                                  {item.url ? <div className="explorebtn">{widgetSettingsConfig[list.id]?.is_button ? <button onClick={() => this.getUrl(item.banner_type,item.url)}>{widgetSettingsConfig[list.id].anchor_text ? widgetSettingsConfig[list.id].anchor_text :"Explore"}</button>:""}</div> :""}
                                </div>
                              </div>
                            )}
                          </Carousel>
                          }
                        </section></>
                      }
                        {list.type === 'quick_links' && <><section>
                        <div className="overlay-actions">
                          <a href="#" onClick={() => this.addWidget(list,widget,widgetRows[key-1])} title="Add Widget"><img src={addwidget}/></a>
                          <a href="#" onClick={() =>this.addRow(widgetRows?.length && widgetRows[key-1])} title="Add Row"><img src={addicon}/></a>
                          <a href="#" onClick={() => this.getSettingInfo(list)} title="Edit Widget"><img src={editicon} alt="Edit widget" /></a>
                          <a href="#" onClick={() => this.isDeleteWidget(list.id,list.type)} title="Delete Widget"><img src={Delete} alt="delete widget" /></a>
                        </div> 
                        <div className="quick-links-widget widget">
                        <div className="heading-title">{list.title && list.title}</div>
                          <ol>
                            <>{widgetdata[18]?.live_class?.length ? widgetdata[18].live_class.map(item =>
                              <li Style={item.action_label && (item.action_label === "Join Class" || item.action_label === "Scheduled") ? "display:flex":"display:none"}>
                                <div>{item.title}</div>
                                <div>
                                  {this.setLink(item)}
                                </div>
                              </li>
                            ):""}</>

                            <>{widgetdata[list.id]?.assessment?.length ? widgetdata[list.id].assessment.map(item =>
                              <li>
                                <div>{item.title}</div>
                                
                                {item.actions.map( action =>
                                  <div><a index={action.id} 
                                onClick={()=>this.attemptAndReview(action.label, item.content_id)}>{action.label}</a></div>
                                )}
                              </li>
                            ) :""}</>

                            <>{widgetdata[list.id]?.assignment?.length ? widgetdata[list.id].assignment.filter(dtl => new Date(dtl.due_date).getTime() >= new Date().getTime()).map(item =>
                              <li>
                                <div>{item.title}</div>
                                
                                {item.actions.map( action =>
                                  <div>
                                    {action.label != "Scheduled" ? <Link to={{pathname: `/learner/assignment-review/${item.assignment_id}` , state:{action:action.label,subject:item?.subject_name,id:item?.subject_id}}}>
                                       {action.label}
                                    </Link> : <span className="action-class">{action.label}</span>}
                                  </div>
                                )}
                              </li>
                            ) :""}</>
                            
                            
                            {/*<li>
                              <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo ante velit.</div>
                              <div><a href="#">Join</a></div>
                            </li>
                            <li>
                              <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut commodo ante velit</div>
                              <diev>Scheduled for 21 may (Tuesday)<a onClick={() => this.on_refer_click(list.id)} > click </a></div>
                            </li>*/}
                          </ol>
                        </div></section></>
                      }
                      {list.type === 'noticeboard' && <><section>
                        <div className="overlay-actions">
                          <a href="#" onClick={() => this.addWidget(list,widget,widgetRows[key-1])} title="Add Widget"><img src={addwidget}/></a>
                          <a href="#" onClick={() =>this.addRow(widgetRows?.length && widgetRows[key-1])} title="Add Row"><img src={addicon}/></a>
                          <a href="#" onClick={() => this.getSettingInfo(list)} title="Edit Widget"><img src={editicon} alt="Edit widget" /></a>
                          <a href="#" onClick={() => this.isDeleteWidget(list.id,list.type)} title="Delete Widget"><img src={Delete} alt="delete widget" /></a>
                        </div> 
                        <div className="quick-links-widget widget">
                        <div className="heading-title">{list.title && list.title}</div>
                          <ol>
                            <>{widgetdata[18]?.live_class?.length ? widgetdata[18].live_class.map(item =>
                              <li Style={item.action_label && (item.action_label === "Join Class" || item.action_label === "Scheduled") ? "display:flex":"display:none"}>
                                <div>{item.title}</div>
                                <div>
                                  {this.setLink(item)}
                                </div>
                              </li>
                            ):""}</>

                            <>{widgetdata[list.id]?.assessment?.length ? widgetdata[list.id].assessment.map(item =>
                              <li>
                                <div>{item.title}</div>
                                
                                {item.actions.map( action =>
                                  <div><a index={action.id} 
                                onClick={()=>this.attemptAndReview(action.label, item.content_id)}>{action.label}</a></div>
                                )}
                              </li>
                            ) :""}</>

                            <>{widgetdata[list.id]?.assignment?.length ? widgetdata[list.id].assignment.filter(dtl => new Date(dtl.due_date).getTime() >= new Date().getTime()).map(item =>
                              <li>
                                <div>{item.title}</div>
                                
                                {item.actions.map( action =>
                                  <div>
                                    {action.label != "Scheduled" ? <Link to={{pathname: `/learner/assignment-review/${item.assignment_id}` , state:{action:action.label,subject:item?.subject_name,id:item?.subject_id}}}>
                                       {action.label}
                                    </Link> : <span className="action-class">{action.label}</span>}
                                  </div>
                                )}
                              </li>
                            ) :""}</>
                            
                            
                          
                          </ol>
                        </div></section></>
                      }




                      {/*list.type === 'notifications' && <section>
                        <div className="overlay-actions">
                          <a href="#" onClick={() => this.addWidget(list,widget,widgetRows[key-1])} title="Add Widget"><img src={addwidget}/></a>
                          <a href="#" onClick={() =>this.addRow(widgetRows?.length && widgetRows[key-1])} title="Add Row"><img src={addicon}/></a>
                          <a href="#" onClick={() => this.getSettingInfo(list)} title="Edit Widget"><img src={editicon} alt="Edit widget" /></a>
                          <a href="#" onClick={() => this.isDeleteWidget(list.id,list.type)} title="Delete Widget"><img src={Delete} alt="delete widget" /></a>
                        </div> 
                        
                        widgetdata[list.id]?.length && widgetdata[list.id].map(item =>
                            <div dangerouslySetInnerHTML={{__html: item.content}} />
                        )

                        </section>
                      
                      */}

                      {list.type === 'recommendation_engine' && <><section>
                        <div className="overlay-actions">
                          <a href="#" onClick={() => this.addWidget(list,widget,widgetRows[key-1])} title="Add Widget"><img src={addwidget}/></a>
                          <a href="#" onClick={() =>this.addRow(widgetRows?.length && widgetRows[key-1])} title="Add Row"><img src={addicon}/></a>
                          <a href="#" onClick={() => this.getSettingInfo(list)} title="Edit Widget"><img src={editicon} alt="Edit widget" /></a>
                          <a href="#" onClick={() => this.isDeleteWidget(list.id,list.type)} title="Delete Widget"><img src={Delete} alt="delete widget" /></a>
                        </div> 
                      <div className="quick-recommendations-widget">
                           <div className="heading-title">{list.title && list.title}</div>
                        <ol>
                          {widgetdata[list.id]?.length && widgetdata[list.id].map(item =>
                            <li>
                                <a href={item.link} target="_blank">
                                <div className="course-thumb"><img src={item?.image ? item?.image:""} /></div>
                                <div className="course-content">
                                    <div className="title">{item?.course}</div>
                                    <div className="details"><span>{item?.university}</span><span>{item?.location}</span></div>
                                </div>
                                </a>
                            </li>
                          )}
                        </ol>
                      </div>
                      </section></>
                      
                      }

                      {list.type === 'forms' && <><section>
                        <div className="overlay-actions">
                          <a href="#" onClick={() => this.addWidget(list,widget,widgetRows[key-1])} title="Add Widget"><img src={addwidget}/></a>
                          <a href="#" onClick={() =>this.addRow(widgetRows?.length && widgetRows[key-1])} title="Add Row"><img src={addicon}/></a>
                          <a href="#" onClick={() => this.getSettingInfo(list)} title="Edit Widget"><img src={editicon} alt="Edit widget" /></a>
                          <a href="#" onClick={() => this.isDeleteWidget(list.id,list.type)} title="Delete Widget"><img src={Delete} alt="delete widget" /></a>
                        </div> 
                        <div className="heading-title">{list.title && list.title}</div>
                        <a href={"/admin/mygoal/"+list.id} target="_blank">
                          <ol className="referal-widget">
                            <li>
                              <div className="course-thumb"><img src={subscribeicon} alt="subscribeicon"/></div>
                              <div className="arrow"><img src={arrowicon}/></div>
                              <div className="course-title">{widgetdata[list.id]?.title}</div>
                              <div className="course-description">{widgetdata[list.id]?.description}</div>
                            </li>
                          </ol>
                        </a>
                        </section></>
                      }
                    
                        </div>
                      </div>
                    ):""}
                  </div>
                  </section>}</>
                )}

              </div>
            </div>
          {/*Code endhere*/}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.commonReducer.loading,
    saveLanguageWiseData:state.commonReducer.languageData
  }
}

const mapDispatchToProps = (dispatch) => {
  const { getAssessment,widgetQuestionSubmit,getwpUserToken,formCreate,bannerCreate,formList,widgetReorder,getWidgetDataById,instituteWidgetList,widgetDelete,widgetSubmit,widgetList,getCertificationBannerList } = actions;
  return {
    getwpUserToken: () => dispatch(getwpUserToken()),
    widgetQuestionSubmit : (questions) => dispatch(widgetQuestionSubmit(questions)),
    formCreate: (title,description,success_message,type) =>dispatch(formCreate(title,description,success_message,type)),
    bannerCreate: (title, description, start_date_time, end_date_time, banner_type, level, image, url,content,classs,center) => dispatch(bannerCreate(title, description, start_date_time, end_date_time, banner_type, level, image, url,content,classs,center)),
    widgetDelete: (id) => dispatch(widgetDelete(id)),
    widgetReorder:(reorder) => dispatch(widgetReorder(reorder)),
    getAssessment: (id) => dispatch(getAssessment(id)),
    widgetList: () => dispatch(widgetList()),
    getWidgetDataById : (id) => dispatch(getWidgetDataById(id)),
    instituteWidgetList: () =>dispatch(instituteWidgetList()),
    formList:() => dispatch(formList()),
    getCertificationBannerList : (type,center) => dispatch(getCertificationBannerList(type,center)),
    widgetSubmit : (widget_master_id,size,position,data,settings,row,column,selected_widget_id,title) => dispatch(widgetSubmit(widget_master_id,size,position,data,settings,row, column,selected_widget_id,title))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(widgetSettingClass);