import {
  LOADING,
  GET_TOC_LIST,
  TOGGLE_UNIT,
  TOGGLE_CHAPTER,
  TOGGLE_TOPIC,
  SELECTED_CONTENT_ID,
  CONTENT_PREVIEW,
  OPEN_TOC_ID,
  EXERCISE_ATTEMPT,
  CONTENT_NOTES_DETAIL,
  CONTENT_VIDEO_DETAIL,
  CONTENT_SOLVED_EXAMPLE,
  LEARNER_PROFILE
} from "../constants";

import Service from '../../utils/Service';

import common from '../../common';

const toggleUnit = (unitId) => dispatch => {
  dispatch({ type: TOGGLE_UNIT, unitId: unitId });
}

const toggleChapter = (unitId, chapterId) => dispatch => {
  dispatch({ type: TOGGLE_CHAPTER, unitId: unitId, chapterId: chapterId });
}

const toggleTopic = (unitId, chapterId, topicId) => dispatch => {
  dispatch({ type: TOGGLE_TOPIC, unitId: unitId, chapterId: chapterId, topicId: topicId });
}

const selectedContentId = (contentId) => dispatch => {
  dispatch({ type: SELECTED_CONTENT_ID, selectedContentId: contentId });
}

const getTocList = (subject_id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  dispatch({ type: CONTENT_PREVIEW, contentPreview: {} });
  const url = `${common.studentTOC}`
  const response = Service.post(url, { subject_id }, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    dispatch({ type: OPEN_TOC_ID, highlightId: response.data.current_open_toc_id });
    dispatch({ type: GET_TOC_LIST, toc_list: response.data });
    return response.data;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
  })
  return response;
};

const saveTocCompletion = (id, type, subject_id) => async dispatch => {

  const url = `${common.saveTocCompletion}`
  const response = Service.post(url, { id, type, subject_id }, (status, response) => {

    return response.data;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
  })
  return response;
};

const getContentPreview = (contentId, content_subtype) => async dispatch => {
  localStorage.setItem('preview_content_id', contentId);
  localStorage.setItem('content_subtype_name', content_subtype);
  dispatch(selectedContentId(contentId))
  if (content_subtype == 5)
    dispatch(getVideoDetail(contentId))
  else if (content_subtype == 4)
    dispatch(getNotesContentDetail(contentId))
  else if (content_subtype == 3)
    dispatch(getSolvedExample(contentId))
  else if (content_subtype === 0)
    dispatch(getAssgPreview(contentId))
  else {
    dispatch(getAttemptPreview(contentId))
  }
};

const getAssgPreview = (assignment_id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  dispatch({ type: CONTENT_PREVIEW, contentPreview: {} });
  dispatch({ type: CONTENT_NOTES_DETAIL, contentNotesDetail: {} });
  dispatch({ type: CONTENT_SOLVED_EXAMPLE, contentSolvedExample: {} });
  dispatch({ type: CONTENT_VIDEO_DETAIL, contentVideoDetail: {} });
  let url = '';
  if (localStorage.getItem("is_faculty") == "true")
    url = `${common.assignmentReview}`
  else
    url = `${common.learnerAssignmentReview}`
  Service.post(url, { assignment_id }, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    if (localStorage.getItem("is_faculty") == "true")
      dispatch({ type: CONTENT_PREVIEW, contentPreview: response.data.data });
    else
      dispatch({ type: CONTENT_PREVIEW, contentPreview: response.data });

  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message)
  })
}

const getAttemptPreview = (content_id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  dispatch({ type: CONTENT_PREVIEW, contentPreview: {} });
  dispatch({ type: CONTENT_NOTES_DETAIL, contentNotesDetail: {} });
  dispatch({ type: CONTENT_SOLVED_EXAMPLE, contentSolvedExample: {} });
  dispatch({ type: CONTENT_VIDEO_DETAIL, contentVideoDetail: {} });
  const url = `${common.getContentPreview}`
  const response = Service.post(url, { content_id }, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    dispatch({ type: CONTENT_PREVIEW, contentPreview: response.data });

    return response;

  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message)
  })
  return response;
}

const getNotesContentDetail = (contentId) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  dispatch({ type: CONTENT_PREVIEW, contentPreview: {} });
  dispatch({ type: CONTENT_NOTES_DETAIL, contentNotesDetail: {} });
  dispatch({ type: CONTENT_SOLVED_EXAMPLE, contentSolvedExample: {} });
  dispatch({ type: CONTENT_VIDEO_DETAIL, contentVideoDetail: {} });
  Service.post(common.getNotesContentDetail, { "content_id": contentId }, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    dispatch({ type: CONTENT_NOTES_DETAIL, contentNotesDetail: response.data });
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message)
  })
};

const getVideoDetail = (contentId) => dispatch => {
  dispatch({ type: LOADING, loading: true });
  dispatch({ type: CONTENT_PREVIEW, contentPreview: {} });
  dispatch({ type: CONTENT_NOTES_DETAIL, contentNotesDetail: {} });
  dispatch({ type: CONTENT_SOLVED_EXAMPLE, contentSolvedExample: {} });
  dispatch({ type: CONTENT_VIDEO_DETAIL, contentVideoDetail: {} });
  const url = `${common.getVideoDetail}`
  let data = new FormData();
  data.append('content_id', contentId);
  data.append('hls_mode', 1);
  Service.post(url, data, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    dispatch({ type: CONTENT_VIDEO_DETAIL, contentVideoDetail: response.data });
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message)
  })
}

const getSolvedExample = (content_id) => dispatch => {
  dispatch({ type: LOADING, loading: true });
  dispatch({ type: CONTENT_PREVIEW, contentPreview: {} });
  dispatch({ type: CONTENT_NOTES_DETAIL, contentNotesDetail: {} });
  dispatch({ type: CONTENT_SOLVED_EXAMPLE, contentSolvedExample: {} });
  dispatch({ type: CONTENT_VIDEO_DETAIL, contentVideoDetail: {} });
  const url = `${common.getSolvedExample}`
  Service.post(url, { content_id }, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    dispatch({ type: CONTENT_SOLVED_EXAMPLE, contentSolvedExample: response.data });
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message)
  })
}

const getAssessmentAttempt = (content_id) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.getAssessmentAttempt}`
  Service.post(url, { content_id }, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    dispatch({ type: EXERCISE_ATTEMPT, exerciseAttempt: response.data });
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message)
  })
};

const saveAttemptSubmit = (attempt_id, questions, attempt_file) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.saveAttemptSubmit}`;
  const formData = new FormData();
  formData.append("attempt_id", attempt_id);
  formData.append("questions", JSON.stringify(questions)); // Convert questions to string if it's an array or object
  formData.append("attempt_file", attempt_file);


  Service.post(url, formData, (status, response) => {
    dispatch({ type: LOADING, loading: false });
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message)
  })
};

const saveQuestionSubmit = (attempt_id, questions) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  const url = `${common.saveQuestionSubmit}`;
  const response = Service.post(url, { attempt_id, ...questions }, (status, response) => {
    dispatch({ type: LOADING, loading: false });
    return response.data;
  }).catch(er => {
    dispatch({ type: LOADING, loading: false });
    console.error('error', er.message)
  })
  return response
};

export default {
  toggleUnit,
  toggleChapter,
  toggleTopic,
  selectedContentId,
  getTocList,
  getNotesContentDetail,
  getContentPreview,
  getAttemptPreview,
  getSolvedExample,
  getAssessmentAttempt,
  saveAttemptSubmit,
  saveQuestionSubmit,
  saveTocCompletion
}