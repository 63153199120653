import './AttendanceReport.css';
import React, { useEffect, useState } from 'react';
import actions from '../../redux/actions';
import { connect } from 'react-redux';
import common from '../../common';
import axios from 'axios';

const UserAttendanceReport = (props) => {
    const [selectedClass, setSelectedClass] = useState('');
    const [selectedBatch, setSelectedBatch] = useState('');
    const [selectedSubject, setSelectedSubject] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [selectEndDate, setSelectEndDate] = useState('');
    const [classList, setClassList] = useState([]);
    const [batchList, setBatchList] = useState([]);
    const [subjectList, setSubjectList] = useState([]);
    const [attendanceData, setAttendanceData] = useState([]);
    const [lectures, setLectures] = useState({})

    

    const { studentAttendanceReport, getClassList, getSectionList2, getSubjectList2 } = props;
    let centerid = localStorage.getItem("center_id");

    useEffect(() => {
        const fetchClassList = async () => {
            try {
                const response = await getClassList();
                setClassList(response.data);
            } catch (error) {
                console.error("Failed to fetch class list:", error);
            }
        };
        fetchClassList();

    }, [getClassList]);

    const fetchAttendanceReport = async ({ is_download = false }) => {
        try {
            const attendanceResp = await studentAttendanceReport(selectedSubject, selectedClass, selectedBatch, selectedDate, selectEndDate, is_download);
            if (attendanceResp && attendanceResp.data && attendanceResp.data.attendance_data) {
                setAttendanceData(attendanceResp.data.attendance_data);
                setLectures(attendanceResp.data.lectures);
            } else {
                setAttendanceData([]);
            }
        } catch (error) {
            console.error("Failed to fetch attendance report:", error);
            setAttendanceData([]);
        }
    };





    const onClassChange = async (e) => {
        const selectedClassId = e.target.value;
        setSelectedClass(selectedClassId);

        try {
            const response = await getSectionList2(centerid, selectedClassId);
            setBatchList(response.data);
        } catch (error) {
            console.error("Failed to fetch batch list:", error);
        }
    };

    const onBatchChange = async (e) => {
        const selectedBatchId = e.target.value;
        setSelectedBatch(selectedBatchId);

        try {
            const response = await getSubjectList2(centerid, selectedClass);
            setSubjectList(response.data);
        } catch (error) {
            console.error("Failed to fetch subject list:", error);
        }
    };

    const onSubjectChange = async (e) => {
        const selectedSubjectId = e.target.value;
        setSelectedSubject(selectedSubjectId);
    };

    const onDateChange = (e) => {
        const selectedDate = e.target.value;
        setSelectedDate(selectedDate);

        // try {
        //     fetchAttendanceReport()
        // } catch (error) {
        //     console.error("Failed to fetch attendance report after subject selection:", error);
        // }
    };
    const onEndDateChange = (e) => {
        const selectEndDate = e.target.value;
        setSelectEndDate(selectEndDate);

        // try {
        //     fetchAttendanceReport()
        // } catch (error) {
        //     console.error("Failed to fetch attendance report after subject selection:", error);
        // }
    };



    const downloadCSV = async () => {
        const formData = new FormData();
        formData.append('subject_id', selectedSubject);
        formData.append('classs_id', selectedClass);
        formData.append('batch_id', selectedBatch);
        formData.append('date', selectedDate);
        formData.append('end_date', selectEndDate);
        formData.append('is_download', 1);
        try {
            axios.post(`${common.apiBase}${common.studentAttendanceReport}`, formData, {
                headers: {
                    Authorization: 'token ' + localStorage.getItem('token'),
                },
                responseType: 'blob', // Important to handle CSV download properly
            })
                .then(res => {
                    const blob = new Blob([res.data], { type: 'text/csv' }); // Convert the response to a CSV blob
                    const filename = `Attendence-Report-${selectedDate}-${selectEndDate}.csv`;

                    // Create a link element to download the file
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.setAttribute('download', filename);

                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link); // Clean up


                })
                .catch(err => {

                    console.error('Error:', err.message);
                });
        } catch (error) {
            console.error("Error in downloadReportClick:", error);

        }

    };

    const convertToCSV = (data) => {
        const uniqueDates = getUniqueClassOnDates();
        const headers = ['S.No', 'Roll No', 'Student Name', 'Email', ...uniqueDates, 'Expected Attendance in Days', 'No of Days Present', 'Present %age'];
        const rows = data.map((student, index) => [
            index + 1,
            student.roll_no || 'N/A',
            student.name,
            student.email,
            ...uniqueDates.map(date => student.attendance[date] ? student.attendance[date].join(', ') : 'Absent'),
            student.total_expected_attendance,
            student.total_class_attended,
            student.attendance_percentage
        ]);

        const csvContent = [headers, ...rows].map(e => e.join(",")).join("\n");
        return csvContent;
    };

    const getUniqueClassOnDates = () => {


        const uniqueDates = new Set();

        Object.keys(lectures).forEach((key) => {
            if (Array.isArray(lectures[key])) {
                lectures[key].forEach((_, index) => {
                    uniqueDates.add(`${key} \nClass ${index + 1}`)
                });
            }
        });
        return [...uniqueDates];


    };

    const getUniqueClassOnDatesArray = getUniqueClassOnDates();

    return (
        <div className="admin-div">
            <div className='attendance-report-header'>
                <h2>Attendance Report</h2>
                <div className="export-button-container">
                    <button style={{ margin: 0 }} onClick={fetchAttendanceReport}>View Data</button>
                   {attendanceData.length != 0 &&  <button style={{ margin: 0 }} onClick={downloadCSV}>Export</button>}
                </div>
            </div>
            <div className="dropdown-container">
                <select onChange={onClassChange} value={selectedClass}>
                    <option value="">Select Class</option>
                    {classList.map(classs => (
                        <option value={classs.id} key={classs.id}>
                            {classs.label}
                        </option>
                    ))}
                </select>

                <select onChange={onBatchChange} value={selectedBatch}>
                    <option value="">Select Batch</option>
                    {batchList && batchList.map(batchItem => (
                        <option value={batchItem.id} key={batchItem.id}>
                            {batchItem.label}
                        </option>
                    ))}
                </select>

                <select onChange={onSubjectChange} value={selectedSubject}>
                    <option value="">Select Subject</option>
                    {subjectList.map(subjectItem => (
                        <option value={subjectItem.id} key={subjectItem.id}>
                            {subjectItem.label}
                        </option>
                    ))}
                </select>


                <input type="date" onChange={onDateChange} value={selectedDate} />
                <input type="date" onChange={onEndDateChange} value={selectEndDate} />


            </div>
            <div className="main-part">
                <div className="sub-head">
                    <div className="attendance-report">




                        <table>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Roll No</th>
                                    <th>Student Name</th>
                                    <th>Email</th>
                                    {/* {getUniqueClassOnDatesArray.map((date, index) => (
                                        <th key={index}>{date}</th>
                                    ))} */}
                                    {getUniqueClassOnDatesArray.map((date, index) => (
                                        <th key={index}>{date.split('\n').map((line, i) => (
                                            <div style={{width: '100px'}}>
                                                <p key={i}>{line}<br /></p>
                                            </div>
                                        ))}</th>
                                    ))}
                                    <th>Expected Attendance in Days</th>
                                    <th>No of Days Present</th>
                                    <th>Present %age</th>
                                </tr>
                            </thead>
                            <tbody>
                                {attendanceData.length === 0 ? (
                                    <tr>
                                        <td colSpan={getUniqueClassOnDatesArray.length + 8}>No data available</td>
                                    </tr>
                                ) : (
                                    attendanceData.map((student, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{student.roll_no || 'N/A'}</td>
                                            <td>{student.name}</td>
                                            <td>{student.email}</td>
                                            {getUniqueClassOnDatesArray.map(date => {
                                                const classKey = date?.split('Class')[0].trim();
                                                const classIndex = date?.split('Class')[1] - 1;
                                                const attendanceValue = student.attendance[classKey] ? student.attendance[classKey][classIndex] : null;
                                                return <td key={date}>{attendanceValue !== undefined ? attendanceValue : 'N/A'}</td>;
                                            })}

                                            <td>{student.total_expected_attendance}</td>
                                            <td>{student.total_class_attended}</td>
                                            <td>{student.attendance_percentage}%</td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    // Map your state to props if needed
});

const mapDispatchToProps = (dispatch) => {
    const { studentAttendanceReport, getClassList, getSectionList2, getSubjectList2 } = actions;
    return {
        studentAttendanceReport: (subject_id, classs_id, batch_id, date, end_date, isDownload) => dispatch(studentAttendanceReport(subject_id, classs_id, batch_id, date, end_date, isDownload)),
        getClassList: () => dispatch(getClassList()),
        getSectionList2: (center_id, class_id) => dispatch(getSectionList2(center_id, class_id)),
        getSubjectList2: (center_id, class_id) => dispatch(getSubjectList2(center_id, class_id)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAttendanceReport);
